import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button } from "antd";
import CustomTooltip from "components/tooltip/tooltip";
import { InfoCircleOutlined } from "@ant-design/icons";
import { updateOnboardingFranchise } from "store/OnboardingFranchise/OnboardingFranchiseStore";
import { selectOnboardingFranchise } from "store/OnboardingFranchise/OnboardingFranchiseStore";

enum EnumStatOptions {
    pending = 'pending',
    verified = 'verified'
}

export const OnboardingLanding = () => {
    const dispatch = useDispatch();
    const onboardingFranchise = useSelector(selectOnboardingFranchise);
    const [domain, setDomain] = useState<string>("");
    const [domainStatus, setDomainStatus] = useState<EnumStatOptions>(EnumStatOptions.pending);
    const [showAlwaysOnTooltip, setShowAlwaysOnTooltip] = useState(false);

    useEffect(() => {
        if (onboardingFranchise) {
            setDomain(onboardingFranchise.lpDomain || "");
            setDomainStatus(onboardingFranchise.lpDomainStat || EnumStatOptions.pending);
        }
    }, [onboardingFranchise]);


    console.log(onboardingFranchise)

    const onFinish = (values: any) => {
        const updatedFranchise = {
            lpDomain: domain,
        };

        dispatch(updateOnboardingFranchise({
            ...onboardingFranchise,
            ...updatedFranchise
        }));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    const getStatusBadge = (status: EnumStatOptions) => {
        const colorHex = status === EnumStatOptions.verified ? "#009051" : "#FF5566";
        return (
            <div className="ml-9">
                <div className="shadow-[0px_0px_8px_0px_#15072566] bg-white rounded-lg capitalize font-bold flex items-center p-[6px_19px]">
                    {status}
                    <div
                        className="w-4 h-4 rounded-full ml-6"
                        style={{ backgroundColor: colorHex }}
                    ></div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="w-5/6">
                <div>
                    <Form
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className="container-form w-5/6"
                        layout="vertical"
                    >
                        <Form.Item name="domain" rules={[{ required: true}]}  className="flex ">
                            <div className="flex items-center">
                                <div className="w-[318px]">
                                    <Input value={domain} onChange={(e) => setDomain(e.target.value)} placeholder="Domain" />
                                </div>

                                {getStatusBadge(domainStatus)}
                            </div>
                        </Form.Item>

                        <div className="flex justify-start items-center">
                            <Button
                                htmlType="submit"
                                className="primary-button mt-0 pointer-hover"
                                style={{ backgroundColor: "#ff7b00", display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'auto', padding: '8px 44px' }}
                            >
                                <div className="primary-button-text ">SAVE</div>
                            </Button>
                            <CustomTooltip
                                open={showAlwaysOnTooltip}
                                onMouseEnter={() => setShowAlwaysOnTooltip(true)}
                                onMouseLeave={() => setShowAlwaysOnTooltip(false)}
                                title="Your landing page domain is validated for all extensions needed for standard conversion tracking and optimization."
                                placement="right-start"
                                arrow
                                >
                                <InfoCircleOutlined
                                    style={{
                                    color: "#424242",
                                    fontSize: "20px",
                                    marginLeft: "15px",
                                    marginRight: "20px",
                                    }}
                                />
                            </CustomTooltip>
                        </div>

                    </Form>
                </div>
            </div>
        </>
    );
};

export default OnboardingLanding;