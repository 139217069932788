import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OnboardingLocation} from "../../models/OnboardingLocation";
import services from "../../services";
import { RootState } from "../index";

export const fetchOnboardingLocations = createAsyncThunk("onboarding-locations/fetch", async () => {
  return services.onboardingLocationService.getOnboardingLocations();
});



export const onboardingLocationSlice = createSlice({
  name: "onboardingLocation",
  initialState: {
    onboardingLocations: undefined as OnboardingLocation[] | undefined,
    isOnboardingLocationsLoaded: false,
  },
  reducers: {
    setOnboardingLocations: (state, { payload: onboardingLocations }: PayloadAction<OnboardingLocation[]>) => {
      state.onboardingLocations = onboardingLocations;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOnboardingLocations.fulfilled, (state, action) => {
      console.log('Obtained the following config for onboarding locations');
      console.log(action.payload);
      state.onboardingLocations = action.payload;
      state.isOnboardingLocationsLoaded = true;
    });

    builder.addCase(fetchOnboardingLocations.rejected, (state, action) => {
      state.isOnboardingLocationsLoaded = true;
    });
  },
});

export const selectOnboardingLocations = (state: RootState): OnboardingLocation[] | undefined =>
  state.onboardingLocation.onboardingLocations;

export const selectIsOnboardingLocationsLoaded = (state: RootState): boolean =>
  state.onboardingLocation.isOnboardingLocationsLoaded;

export const { setOnboardingLocations } = onboardingLocationSlice.actions;

export default onboardingLocationSlice.reducer;
