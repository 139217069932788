import { combineReducers } from "redux";
import authReducer from "./Auth/reducer";
import adCampaigns from "./AdCampaigns/AdCampaignStore";
import events from "./Events/EventStore";
import channels from "./Channels/ChannelStore";
import packages from "./customPackages/CustomPackagesStore";
import templates from "./CreativeTemplates/CreativeTemplateStore";
import campaignForm from "./campaignForm/CampaignForm";
import reports from "./Reports/ReportsStore";
import locations from "./Location/LocationStore";
import budgets from "./Budgets/BusgetStore";
import accounts from "./Account/AccountStore";
import qrLandingCodes from "./QRLandingCodes/QRLandingCodesStore";
import qrPromCodes from "./QRPromCodes/QRPromCodesStore";
import qrCampaigns from "./QRCampaign/QRCampaignsStore";
import adminAccounts from "./AdminAccount/AdminAccountStore";
import adminManagers from "./AdminManager/AdminManagerStore";
import franchises from "./Franchise/FranchiseStore";
import whitelabels from "./WhiteLabel/WhiteLabelStore";
import uploadReducer from "./Image/ImageStore";
import demographics from "./Demographics/DemographicsStore";
import customReport from "./CustomReport/CustomReportStore";
import mediaTemplate from "./mediaTemplate/MediaTemplate";
import navigation from "./Navigation/NavigationStore";
import monthlyInvoicesList from "./MonthlyInvoices/MonthlyInvoiceStore";
import fileUpload from "./PlatformSupport/Upload";
import fileDownload from "./PlatformSupport/Download";
import supportForm from "./PlatformSupport/SupportForm";
import orderForm from "./PlatformSupport/OrderForm";
import menuStore from "./Menu/MenuStore";
import googleAdsCampaigns from "./GoogleAdsCampaign/GoogleAdsCampaignStore";
import alerts from "./Alerts/AlertStore";
import customKeyword from "./CustomKeyword/CustomKeywordStore";
import customCreative from "./CustomCreative/CustomCreativeStore";
import emails from "./Email/EmailStore";
import spendPacingReport from "./SpendPacingReport/SpendPacingReportStore";
import keywordHelper from "./KeywordHelper/KeywordHelperStore";
import onboardingFranchise from "./OnboardingFranchise/OnboardingFranchiseStore";
import onboardingLocation from "./OnboardingLocation/OnboardingLocationStore";

export const rootReducer = combineReducers({
  authReducer,
  accounts,
  adminAccounts,
  adminManagers,
  adCampaigns,
  events,
  channels,
  demographics,
  packages,
  franchises,
  templates,
  campaignForm,
  reports,
  locations,
  qrLandingCodes,
  qrPromCodes,
  qrCampaigns,
  whitelabels,
  upload: uploadReducer,
  customReport,
  mediaTemplate,
  fileUpload,
  fileDownload,
  navigation,
  monthlyInvoicesList,
  supportForm,
  orderForm,
  menuStore,
  googleAdsCampaigns,
  alerts,
  customKeyword,
  customCreative,
  emails,
  budgets,
  spendPacingReport,
  keywordHelper,
  onboardingFranchise,
  onboardingLocation
});

export type RootState = ReturnType<typeof rootReducer>;
