import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from "react"
import { InfoCircleOutlined } from "@ant-design/icons";
import CustomTooltip from "../../tooltip/tooltip";

import { OnboardingLocation } from "../../../models/OnboardingLocation";
import { useNavigate } from "react-router-dom";

interface AdLogProps {
    onboardingLocation: OnboardingLocation | undefined
}

export const OnboardingAdLog = (props: AdLogProps) => {
    
    const [showAlwaysOnTooltip, setShowAlwaysOnTooltip] = useState(false);
    
    return ( <>
        <div className="flex items-end gap-x-12">

            { props.onboardingLocation && props.onboardingLocation.adLogStat === "verified" ? (
                
                <>
                    <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                        <span>Verified</span>
                        <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#009051' }}></span>
                    </div>

                    <a
                        href={props.onboardingLocation.adLog!}
                        className="text-base text-gray-700 underline hover:underline hover:text-gray-700"
                        target="_blank"
                        rel="noreferrer"
                    >
                        View
                    </a>

                </>
                
                
            ): (
                <>
                    <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                        <span>Pending</span>
                        <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#FF5566' }}></span>
                    </div>

                    <CustomTooltip
                        open={showAlwaysOnTooltip}
                        onMouseEnter={() => setShowAlwaysOnTooltip(true)}
                        onMouseLeave={() => setShowAlwaysOnTooltip(false)}
                        title="Upload your ad log through the chat assistant. If your ad log has been uplaoded this check will clear when all campaign information is validated."
                        placement="right-start"
                        arrow
                    >
                        <InfoCircleOutlined
                            style={{ color: "black", fontSize: "20px", marginLeft: '15px', marginRight: "20px", marginBottom: "10px" }}
                        />
                    </CustomTooltip>
                </>
            )}

        </div>
    </>)
}

export default OnboardingAdLog;