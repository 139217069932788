import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Franchise } from "../../models/Franchise";
import services from "../../services";
import { FranchiseRowSchema, FranchiseCustomKeywordsSchema, FranchiseGadsSchema, FranchiseFadsSchema, FranchiseGASchema, FranchiseOmniChannelSchema, FranchiseCustomReportsSchema, FranchiseGadsKeywordCreativeSchema, FranchiseCredentialValidation, FranchiseCustomCreativesSchema, IUpdateFranchise } from "../../services/franchiseService";
import { RootState } from "../index";



export const getGoogleAdsReports = createAsyncThunk(
  "franchises/reports/google",
  async (data: { dateRange?: string; startDate?: string; endDate?: string; orderBy?: string; pageToken?: string; locations: string[]; userId?: string}) => {

    return await services.franchiseService.getGoogleAdsReports( data.dateRange, data.startDate, data.endDate, data.orderBy, data.pageToken, data.locations, data?.userId);
  }
)

export const getGoogleAdsKeywordCreativeReports = createAsyncThunk(
  "franchises/reports/keyword-creative-google",
  async (data: { dateRange?: string; orderBy?: string; pageToken?: string; location?: string}) => {
    return await services.franchiseService.getGoogleAdsKeywordCreativeReports(data.orderBy, data.pageToken, data.dateRange, data.location);
  }
)

export const getFacebookAdsReports = createAsyncThunk(
  "franchises/reports/facebook",
  async (data: { dateRange?: string; startDate?: string; endDate?: string; orderBy?: string; pageToken?: string; pageDir?: string; locations?: string[]; userId?: string }) => {
    return await services.franchiseService.getFacebookAdsReports( data.dateRange, data.startDate, data.endDate, data.orderBy, data.pageToken, data.pageDir, data.locations, data?.userId);
  }
)

export const getCustomChannelReports = createAsyncThunk(
  "franchises/reports/custom-channels",
  async (data: { dateRange?: string; startDate?: string; endDate?: string; orderBy?: string; channelId?: string, locations: string[], userId?: string }) => {
    return await services.franchiseService.getCustomChannelReports( data.dateRange, data.startDate, data.endDate, data.orderBy, data.channelId, data.locations, data?.userId);
  }
)

export const getCustomKeywordReports = createAsyncThunk(
  "franchises/reports/custom-keywords",
  async (data: { channel: string, location: string, orderBy: string }) => {
    return await services.franchiseService.getCustomKeywordReports(data.channel, data.location, data.orderBy);
  }
)

export const getCustomCreativeReports = createAsyncThunk(
  "franchises/reports/custom-creatives",
  async (data: { channel: string, location: string, orderBy: string }) => {
    return await services.franchiseService.getCustomCreativeReports(data.channel, data.location, data.orderBy);
  }
)

export const getConversionEvents = createAsyncThunk(
  "franchises/reports/conversions",
  async () => {
    return await services.franchiseService.getGAnalyticsConversionEvents();
  }
)

export const getLandingReports = createAsyncThunk(
  "franchises/reports/landing",
  async (data: { startDate?: string; endDate?: string; orderBy?: string; conversion?: String[]; }) => {
    return await services.franchiseService.getGAnalyticsReports(data.startDate, data.endDate, data.orderBy, data.conversion);
  }
)

export const getFranchise = createAsyncThunk( "franchises/info", async () =>{
  const franchise = await services.franchiseService.getFranchise();
  return franchise;
}
)

export const getCredentialValidation = createAsyncThunk( "franchises/check-service-credentials", async () =>{
  const franchiseCredentialValidations = await services.franchiseService.getCredentialValidation();
  return franchiseCredentialValidations;
}
)

export const getAllFranchises = createAsyncThunk( "franchises/get-franchises", async () =>{
  const allFranchises = await services.franchiseService.getFranchises();
  return allFranchises;
}
)

export const getOmniReports = createAsyncThunk(
  "franchises/reports/omnichannel",
  async (data: { channelName?: string; dateRange?: string; startDate?: string; endDate?: string; userId?: string;}) => {
    return await services.franchiseService.getOmniChannelReports(data.channelName, data.dateRange, data.startDate, data.endDate, data?.userId);
  }
)

export const getSimplifiedOmniReports = createAsyncThunk(
  "franchises/reports/simplified-omnichannel",
  async (data: { channelName?: string; dateRange?: string; startDate?: string; endDate?: string; userId?: string }) => {
    return await services.franchiseService.getSimplifiedOmniChannelReports(data.channelName, data.dateRange, data.startDate, data.endDate, data.userId);
  }
)

export const updateFranchise = createAsyncThunk(
  "franchises/update",
  async (updateFranchise: IUpdateFranchise) => {
    return services.franchiseService.updateFranchise(updateFranchise);
  }
);


//Creates multiple franchises from a csv or xlsx file
// @params
// franchiseSchemaArray (An array of FranchiseRowSchema objects representing the rows in the file)
export const importFranchises = createAsyncThunk<
  Franchise[], //we expect this to return a list of locations
  {
    franchiseSchemaArray: FranchiseRowSchema[]
  },
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "franchises/import",
  async (franchiseSchemaArray, { rejectWithValue }) => {
    try {
      let result = await services.franchiseService.importFranchises(franchiseSchemaArray.franchiseSchemaArray);
      return result;
    } catch (error: any) {
      //console.log( error );
      if (!error.response) {
        throw error;
      }
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      //This is the validation error returned by the Platform API using the tsoa library.
      if (errorData.message == "Validation Failed") {
        //This returns a "details" object which contains both the row number and attribute, as well as 
        //the error identified by tsoa. IE: locationRowSchema.$0.country : {message: "Some error" }
        let details = errorData.details;
        Object.keys(details).forEach(key => {
          //The key contains both the row# and the failed attribute
          //The attribute "message" contains the error message.
          //We attempt to format these in a single string.
          let rowNumber = key.substring(key.indexOf(".") + 2, key.indexOf(".") + 3);
          let colName = key.substring(key.indexOf(".") + 4);
          let rowNumberInt = parseInt(rowNumber) + 1;

          let errorMessage = `<b>Row #${rowNumberInt} ${colName}</b>: ${details[key].message}`;
          formattedErrors.push(errorMessage);
        })
      } else if (errorData.message == "Entity Not Found") {
        formattedErrors.push(errorData.details);
      } else if (errorData.message == "Could Not Process Request") {
        formattedErrors.push(errorData.details);
      }

      return rejectWithValue(formattedErrors);

    }

  }
);

export const franchiseSlice = createSlice({
  name: "franchises",
  initialState: {
    franchiseErrors: [] as String[] | null,
    importFranchisesResult: null as String | null,
    googleAdsReport: {} as FranchiseGadsSchema,
    googleAdsKeywordCreativeReport: {} as FranchiseGadsKeywordCreativeSchema,
    googleAdsLoading: true as boolean,
    franchise: {} as Franchise | null,
    facebookAdsReport: {} as FranchiseFadsSchema,
    customChannelReport: {} as FranchiseCustomReportsSchema,
    customKeywordReport: {} as FranchiseCustomKeywordsSchema,
    customCreativeReport: {} as FranchiseCustomCreativesSchema,
    conversionEvents: [] as String[] | null,
    landingReport: {} as FranchiseGASchema,
    omniReport: {} as FranchiseOmniChannelSchema,
    simplifiedOmniReport: {} as FranchiseOmniChannelSchema,
    tendency: "" as String,
    benchmark: null as String | null,
    allFranchises: [] as Franchise[],
    credentialValidation: {
      googleAds: false,
      googleAnalytics: false,
      meta: false,
      loaded: false,
    } as FranchiseCredentialValidation
  },
  reducers: {
    setFranchiseErrors: (
      state,
      { payload: franchiseErrors }: PayloadAction<String[] | null>
    ) => {
      state.franchiseErrors = franchiseErrors;
    },
    setGoogleAdsLoading: (
      state,
      { payload: googleAdsLoading }: PayloadAction<boolean>
    ) => {
      state.googleAdsLoading = googleAdsLoading;
    },
    setFranchise: (state, {payload: franchise}: PayloadAction<Franchise | null>) => {
      state.franchise = franchise;
    },
    setTendency: (state, {payload: tendency}: PayloadAction<String>) => {
      state.tendency = tendency;
    },
    setBenchmark: (state, {payload: benchmark}: PayloadAction<String | null>) => {
      state.benchmark = benchmark;
    }


  },
  extraReducers: (builder) => {
    builder.addCase(importFranchises.fulfilled, (state, action) => {
      let importedFranchises = action.payload;
      state.importFranchisesResult = `Successfully imported ${importedFranchises.length} new franchises.`;
    });

    builder.addCase(importFranchises.rejected, (state, action) => {
      if (action.meta.rejectedWithValue) {
        //Can't be undefined because we verify it has an actual payload.
        state.franchiseErrors = action.payload!;
      }
    });
    builder.addCase(getGoogleAdsReports.fulfilled, (state, action) => {
      state.googleAdsReport = action.payload;
    });
    builder.addCase(getGoogleAdsKeywordCreativeReports.fulfilled, (state, action) => {
      state.googleAdsKeywordCreativeReport = action.payload;
    });
    builder.addCase(getFranchise.fulfilled, (state, action) => {
      state.franchise = action.payload;
    });
    builder.addCase(getCredentialValidation.fulfilled, (state, action) => {
      console.log(action.payload);
      state.credentialValidation = action.payload;
    });

    builder.addCase(getAllFranchises.fulfilled, (state, action) => {
      state.allFranchises = action.payload;
    });

    builder.addCase(getFacebookAdsReports.fulfilled, (state, action) => {
      state.facebookAdsReport = action.payload;
    });
    builder.addCase(getCustomChannelReports.fulfilled, (state, action) => {
      console.log('obtained the following reports from platform');
      console.log(action.payload);
      state.customChannelReport = action.payload;
    });
    builder.addCase(getCustomKeywordReports.fulfilled, (state, action) => {
      state.customKeywordReport = action.payload;
    });
    builder.addCase(getCustomCreativeReports.fulfilled, (state, action) => {
      state.customCreativeReport = action.payload;
    });
    builder.addCase(getConversionEvents.fulfilled, (state, action) => {
      state.conversionEvents = action.payload;
    });
    builder.addCase(getLandingReports.fulfilled, (state, action) => {
      state.landingReport = action.payload;
    });
    builder.addCase(getOmniReports.fulfilled, (state, action) =>{
      state.omniReport = action.payload;
    })
    builder.addCase(getSimplifiedOmniReports.fulfilled, (state, action) =>{
      state.simplifiedOmniReport = action.payload;
    })
    builder.addCase(updateFranchise.fulfilled, (state, action) => {
      state.franchise = action.payload;
    });
  },
});

export const { setFranchiseErrors, setGoogleAdsLoading, setFranchise, setTendency, setBenchmark} = franchiseSlice.actions;

export const selectConversionEvents = (state: RootState) => state.franchises.conversionEvents;

export const selectLandingReport = (state: RootState) => state.franchises.landingReport;

export const selectOmniReport = (state: RootState) => state.franchises.omniReport;

export const selectSimplifiedOmniReport = (state: RootState) => state.franchises.simplifiedOmniReport;

export const selectGoogleAdsLoading = (state: RootState): Boolean => state.franchises.googleAdsLoading;

export const selectFacebookAdsReports = (state: RootState) =>
  state.franchises.facebookAdsReport

export const selectFranchise = (state: RootState) => state.franchises.franchise

export const selectCustomChannelReports = (state: RootState) =>
  state.franchises.customChannelReport

export const selectCustomKeywordReports = (state: RootState) =>
  state.franchises.customKeywordReport

export const selectCustomCreativeReports = (state: RootState) =>
  state.franchises.customCreativeReport

export const selectGoogleAdsReports = (state: RootState) =>
  state.franchises.googleAdsReport;

export const selectGoogleAdsKeywordCreativeReports = (state: RootState) =>
  state.franchises.googleAdsKeywordCreativeReport;

export const franchiseErrors = (state: RootState): String[] | null =>
  state.franchises.franchiseErrors;

export const importFranchiseResult = (state: RootState): String | null =>
  state.franchises.importFranchisesResult;

export const selectTendency = (state: RootState) => state.franchises.tendency;

export const selectBenchmark = (state: RootState) => state.franchises.benchmark;

export const selectCredentialValidation = (state: RootState) => state.franchises.credentialValidation;

export const selectAllFranchises = (state: RootState) => state.franchises.allFranchises;

export default franchiseSlice.reducer;
