import styles from './SidePanelCreateLocation.module.css'
import closeIcon from '../../../assets/icons/cancel.svg'
import { Button, Form, Input, Modal, Select } from "antd"
import DatePicker from "../../datepicker/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { Event } from "../../../models/Event";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { UsStates } from "../../account/BillingAddress/UsStates";
import { CountryEnum, BudgetEnum } from "../../../models/Location";
import { createLocation, selectLocationToEdit, updateLocation } from "../../../store/Location/LocationStore"
import { AvailableRoutes } from "../../../pages/Routes";
import { ReactComponent as IconArrowRight } from "../../../../assets/icons/arrow-right.svg";
import { ReactComponent as IconArrowBack } from "../../../../assets/icons/orange-back-arrow.svg";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";

interface AppProps {
  isEdit?: boolean;
  view: boolean;
  setView: React.Dispatch<React.SetStateAction<boolean>>;
  editMode: boolean;
}

const SidePanelCreateLocation : React.FC<AppProps> = ({ view, setView, isEdit, editMode }) => {

  const [usSelected, setUsSelected] = useState(false);
  const [standardSelected, setStandardSelected] = useState(false);
  const [viewState, setViewState] = useState(view);
  const { Option } = Select;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationToEdit = useSelector(selectLocationToEdit);

  useEffect(() => {
    if(editMode && locationToEdit?.country === CountryEnum.USA)
    setUsSelected(true);
    else if (editMode && locationToEdit?.country !== CountryEnum.USA)
    setUsSelected(false);
    else if (!editMode)
    setUsSelected(true);
    else
    setUsSelected(false);
}, [editMode, locationToEdit]);

  useEffect(() => {
    if(editMode && locationToEdit?.budgetMode === BudgetEnum.none)
    setStandardSelected(true);
    else if (editMode && locationToEdit?.budgetMode !== BudgetEnum.none)
    setStandardSelected(false);
    else if (!editMode)
    setStandardSelected(true);
    else
    setStandardSelected(false);
}, [editMode, locationToEdit]);

const whiteLabel = useSelector(selectWhiteLabel);

const onFinish = (values: any) => {
    if(editMode && locationToEdit !== null) {
        dispatch(
            updateLocation({
                id: locationToEdit.id,
                owner: values.ownerEmail ?? null,
                name: values.locationName,
                externalId: values.externalId,
                address1: values.address1,
                address2: values.address2,
                city: values.city,
                state: values.state,
                postalCode: values.postalCode,
                country: values.country,
                market: values.market ?? '-',
                budgetMode: values.budget,
                mapEmbed: values.mapEmbed ?? null, // Handle optional fields
                nickname: values.nickname ?? null,
                vigI: values.vigI ?? null,
                vigS: values.vigS ?? null,
            })
        );
    } else {
        dispatch(
            createLocation({
                owner: values.ownerEmail,
                name: values.locationName,
                externalId: values.externalId,
                address1: values.address1,
                address2: values.address2,
                city: values.city,
                state: values.state,
                postalCode: values.postalCode,
                country: values.country,
                market: values.market ?? '-',
                budgetMode: values.budget,
                mapEmbed: values.mapEmbed ?? null, // Handle optional fields
                nickname: values.nickname ?? null,
                vigI: values.vigI ?? null,
                vigS: values.vigS ?? null,
            })
        );
    };
    setView(false);
    // navigate(AvailableRoutes.LOCATION_MANAGER);
};

const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
};

const handleCountryChange = (value: string) => {
    if(value === CountryEnum.USA)
    setUsSelected(true);
    else
    setUsSelected(false);
};

const handleBudgetChange = (value: string) => {
    if(value === BudgetEnum.none)
    setStandardSelected(true);
    else
    setStandardSelected(false);
};

const renderOptions = (state: string) => {
    return <Option key={state} value={state}>{state}</Option>
};

  return (
    <div className={styles[ view ? 'side-panel-container' : 'side-panel-container-hidden']}>
      <div className={styles['side-panel']}>
        <img src={closeIcon} width={30} height={30} alt='close icon' className={styles['close-img']} onClick={() => setView(false)} />

        <h2 style={{marginBottom: "32px" }}>{editMode ? 'Edit outlet' : 'New outlet'}</h2>

        <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="container-form"
            layout="vertical"
            initialValues={{
                ownerEmail: editMode && locationToEdit !== null ? locationToEdit.ownerEmail : '',
                locationName: editMode && locationToEdit !== null ? locationToEdit.name : '',
                externalId: editMode && locationToEdit !== null ? locationToEdit.externalId : '',
                address1: editMode && locationToEdit !== null ? locationToEdit.address1 : '',
                address2: editMode && locationToEdit !== null ? locationToEdit.address2 : '',
                city: editMode && locationToEdit !== null ? locationToEdit.city : '',
                state: editMode && locationToEdit !== null ? locationToEdit.state : '',
                postalCode: editMode && locationToEdit !== null ? locationToEdit.postalCode : '',
                country: editMode && locationToEdit !== null ? locationToEdit.country : CountryEnum.USA,
                market: editMode && locationToEdit !== null ? locationToEdit.marketName : '',
                budget: editMode && locationToEdit !== null ? locationToEdit.budgetMode : '',
                mapEmbed: editMode && locationToEdit !== null ? locationToEdit.mapEmbed : '',
                nickname: editMode && locationToEdit !== null ? locationToEdit.nickname : '',
                vigI: editMode && locationToEdit !== null ? locationToEdit.vigI : '',
                vigS: editMode && locationToEdit !== null ? locationToEdit.vigS : '',
            }}
        >
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className=' w-[40vw] res:w-full' >

                    <Form.Item
                        label="Owner"
                        name="ownerEmail"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Owner email" disabled={editMode && locationToEdit?.userId !== null} />
                    </Form.Item>

                    <Form.Item
                        label="Name"
                        name="locationName"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Location name" />
                    </Form.Item>

                    <Form.Item
                        label="ID"
                        name="externalId"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Location ID" />
                    </Form.Item>

                    <Input.Group compact>
                        <Form.Item
                            label="Address 1"
                            name="address1"
                            style={{ width: "46%" }}
                            rules={[{ required: true }]}
                        >
                            <Input placeholder="Address 1" />
                        </Form.Item>

                        <Form.Item
                            label="Address 2"
                            name="address2"
                            style={{ marginLeft: "8%", width: "46%" }}
                            rules={[{ required: false }]}
                        >
                            <Input placeholder="Address 2" />
                        </Form.Item>
                    </Input.Group>

                    <Input.Group compact>
                        <Form.Item
                            label="City"
                            name="city"
                            style={{ width: "46%" }}
                            rules={[{ required: true }]}
                        >
                            <Input placeholder="City" />
                        </Form.Item>

                        <Form.Item
                            label="State"
                            name="state"
                            style={{ marginLeft: "8%", width: "46%" }}
                            rules={[{ required: true }]}
                        >
                            {usSelected ?
                                <Select>
                                {UsStates.map(renderOptions)}
                                </Select>
                                :
                                <Input placeholder="State"/>
                            }
                        </Form.Item>
                    </Input.Group>

                    <Input.Group compact>
                        <Form.Item
                            label="Postal Code"
                            name="postalCode"
                            style={{  width: "46%" }}
                            rules={[{ required: true }]}
                        >
                            <Input placeholder="Postal Code" />
                        </Form.Item>

                        <Form.Item
                            label="Country"
                            name="country"
                            style={{ marginLeft: "8%", width: "46%" }}
                            rules={[{ required: true }]}
                        >
                            <Select onChange={handleCountryChange}>
                            {Object.keys(CountryEnum).map(renderOptions)}
                            </Select>
                        </Form.Item>
                    </Input.Group>

                    <Input.Group compact>
                        <Form.Item
                            label="Market"
                            name="market"
                            style={{ width: "46%" }}
                            rules={[{ required: true }]}
                        >
                            <Input placeholder="Designated market area"/>
                        </Form.Item>

                        <Form.Item
                            label="Budget Mode"
                            name="budget"
                            style={{ marginLeft: "8%", width: "46%" }}
                            rules={[{ required: true }]}
                        >
                            <Select onChange={handleBudgetChange}>
                            {Object.keys(BudgetEnum).map(renderOptions)}
                            </Select>
                        </Form.Item>
                    </Input.Group>

                    <Input.Group compact>
                        <Form.Item
                            label="Map Embed"
                            name="mapEmbed"
                            style={{ width: "46%" }}
                            rules={[{ required: false }]}
                        >
                            <Input placeholder="Map Embed"/>
                        </Form.Item>

                        <Form.Item
                            label="Nickname"
                            name="nickname"
                            style={{ marginLeft: "8%", width: "46%" }}
                            rules={[{ required: false }]}
                        >
                          <Input placeholder="Nickname"/>
                        </Form.Item>
                    </Input.Group>
                    <Input.Group compact>
                        <Form.Item
                            label="Vig I"
                            name="vigI"
                            style={{ width: "46%" }}
                            rules={[{ required: false }]}
                        >
                            <Input placeholder="Vig I"/>
                        </Form.Item>

                        <Form.Item
                            label="Vig S"
                            name="vigS"
                            style={{ marginLeft: "8%", width: "46%" }}
                            rules={[{ required: false }]}
                        >
                          <Input placeholder="Vig S"/>
                        </Form.Item>
                    </Input.Group>

                </div>
            </div>

            <Input.Group compact>
                <Form.Item style={{ marginTop: 50, width: '100%'  }}>
                    <Button
                        htmlType="submit"
                        className="general-button pointer-hover"
                        style={{ backgroundColor: whiteLabel.primaryColor, width: "100%" }}
                    >
                        <span>{ editMode ? 'SAVE' : 'CREATE'}</span>
                    </Button>
                </Form.Item>
            </Input.Group>
        </Form>
      </div>
    </div>
  )
}

export default SidePanelCreateLocation
