import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectLocations, selectFranchiseLocations } from '../../store/Location/LocationStore';
import LocationCard from "../purchase/locations/LocationCard";
import { Location } from "../../models/Location";
import { Button } from "antd";
import { ReactComponent as IconArrowRight } from "../../assets/icons/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { AvailableRoutes } from "../../pages/Routes";
import SidePanelCreateLocation from "./SidePanelCreateLocation/SidePanelCreateLocation";
import { selectUserAccount } from "store/Account/AccountStore";

const AccountLocations: React.FC = () => {
  const user = useSelector(selectUserAccount);
  const userLocations = useSelector(selectLocations);
  const franchiseLocations = useSelector(selectFranchiseLocations)

  const userLocationsBelongsToOneFranchise: boolean = userLocations.every(location => location.franchiseId === user?.franchiseId);

  console.log('users: ' + userLocationsBelongsToOneFranchise);

  const availableLocations = userLocationsBelongsToOneFranchise
    ? franchiseLocations
    : userLocations;
  
  const navigate = useNavigate();
  const [selectedLocationTooltip, setSelectedLocationTooltip] = useState('');
  const [view, setView] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const renderLocations = (location: Location) => {
    return (
      <LocationCard
        key={`location card: ${location.id}`}
        location={location}
        canEdit={true}
        setEditMode={setEditMode}
        setView={setView}
      />
    );
  };

  const selectLocationTooltip = (location: Location) => {
    setSelectedLocationTooltip(location.id);
  }

  return <div className="">
      <div>
        <h1 className="mb-3 text-2xl">Your outlets</h1>
        <div className="locations-container">
          {availableLocations.map(renderLocations)}
        </div>
        <Button
          className="primary general-button pointer-hover"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClick={() => {
            //navigate(AvailableRoutes.CREATE_LOCATION);
            setView(true);
            setEditMode(false);
          }}
        >
          NEW
          <IconArrowRight />
        </Button>
      </div>

      {view && (
        <SidePanelCreateLocation editMode={editMode} view={view} setView={setView} />
      )}
  </div>;
};

export default AccountLocations;
