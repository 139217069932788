import { Radio, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Location } from "../../../models/Location";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info-icon.svg";
import { ReactComponent as ActiveLocationIcon } from "../../../assets/icons/feed-selected.svg";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { AvailableRoutes } from "../../../pages/Routes";
import { useDispatch } from "react-redux";
import { setLocationToEdit } from "../../../store/Location/LocationStore";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { useSelector } from "react-redux";
import CustomTooltip from "../../tooltip/tooltip";

interface LocationCardProps {
  location: Location;
  canEdit: boolean;
  opendTooltip?: string;
  selectLocationTooltip?: (location: Location) => void;
  defaultChecked?: boolean;
  showDetails?: () => void;
  disableTooltip?: boolean;
  setView?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationCard = ({ location, canEdit, opendTooltip, selectLocationTooltip, defaultChecked, showDetails, disableTooltip, setView, setEditMode }: LocationCardProps) => {
  const {
    id,
    name,
    externalId,
    address1,
    proximity,
    city,
    state,
    postalCode,
    phoneNumber,
    isRegion,
    scope,
    scopeLabel,
    places,
    active
  } = location;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const whiteLabel = useSelector(selectWhiteLabel);

  useEffect(() => {
    if (opendTooltip !== location.id)
      setTooltipIsOpen(false)
  }, [opendTooltip, location]);

  const renderTooltip = () => {
    return (
      <div>
      </div>
    )
  };

  const handleButtonClick = () => {
    dispatch(setLocationToEdit(location));

      if (setEditMode !== undefined) {
        setEditMode(true);

        if (setView !== undefined) {
          setView(true);
        }
      }
  };



  return (
    <div className="location-card">
      <div className="location-card-header">
        <div className="location-card-title truncate">{name}</div>
        
        <div className="location-card-location">
          { active ? <ActiveLocationIcon className="primary-icon active-location-icon" width="25" height="25" /> : '' }
        </div>
        {canEdit ?
          <InfoIcon
            className="primary-icon pointer-hover"
            onClick={handleButtonClick}
          />
          :
          <Radio
            value={id}
            sx={{
              color: whiteLabel.primaryColor,
              "&.Mui-checked": {
                color: whiteLabel.primaryColor,
              },
              "& .MuiSvgIcon-root": {
                fontSize: 35,
              },
            }}
            defaultChecked={defaultChecked}
            className="location-card-radio"
          />
        }
      </div>
      <div className="location-card-location-title">
        {isRegion ? 'Region ID' : 'ID'}
        : <b>{externalId}</b>
      </div>
      <div className="location-card-body">
        <div className="location-card-body-left-column">
          <div>
            {isRegion ? scopeLabel : [city, state].join(', ')}
          </div>
        </div>
        {!disableTooltip && selectLocationTooltip ?
          <div className="location-card-body-right-column">
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <CustomTooltip
                open={tooltipIsOpen && opendTooltip === id}
                onClick={() => {
                  setTooltipIsOpen(!tooltipIsOpen);
                  selectLocationTooltip(location);
                }}
                title={renderTooltip()}
                arrow
              >
                <InfoCircleOutlined
                  style={{ color: whiteLabel.primaryColor, fontSize: "25px", marginLeft: '5px', marginRight: "20px" }}
                />
              </CustomTooltip>
            </div>
          </div>
          : null
        }
      </div>
    </div>
  );
};

export default React.memo(LocationCard);