import { selectChannels, selectIsChannelLoaded } from "../../../store/Channels/ChannelStore";
import { useSelector, useDispatch } from 'react-redux';
import { InfoCircleOutlined } from "@ant-design/icons";
import CustomTooltip from "../../tooltip/tooltip";

import { useNavigate } from "react-router-dom";

import { fetchChannels } from "../../../store/Channels/ChannelStore";

import { useEffect, useState } from "react";
import { OnboardingLocation } from "../../../models/OnboardingLocation";

interface MediaPlanProps {
    onboardingLocation: OnboardingLocation | undefined
}


export const OnboardingMediaPlan = ( props: MediaPlanProps ) => {
    
    const dispatch = useDispatch();
    const [showAlwaysOnTooltip, setShowAlwaysOnTooltip] = useState(false);

    const isChannelsLoaded = useSelector( selectIsChannelLoaded );
    const franchiseChannels = useSelector(selectChannels);

    const availableChannels =  props.onboardingLocation && props.onboardingLocation.activeChannels ? props.onboardingLocation.activeChannels?.split(",") : [];

    useEffect(() => {
        if( !isChannelsLoaded ){
          dispatch(fetchChannels({id: "accountId"}));
        }
    }, [isChannelsLoaded])

    return ( <>
        <div className="w-4/12">
            
            <div className="flex items-end gap-x-12">

                { props.onboardingLocation && props.onboardingLocation.mediaPlanStat === "verified" ? (
                    
                    <>
                        <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                            <span>Verified</span>
                            <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#009051' }}></span>
                        </div>

                        <a
                            href={props.onboardingLocation.mediaPlan!}
                            className="text-base text-gray-700 underline hover:underline hover:text-gray-700"
                            target="_blank"
                            rel="noreferrer"
                        >
                            View
                        </a>
                    </>
                    
                    
                ): (
                    <>
                        <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                            <span>Pending</span>
                            <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#FF5566' }}></span>
                        </div>

                        <CustomTooltip
                            open={showAlwaysOnTooltip}
                            onMouseEnter={() => setShowAlwaysOnTooltip(true)}
                            onMouseLeave={() => setShowAlwaysOnTooltip(false)}
                            title="Upload your media plan through the chat assistant. This check will clear when all channel and budget information is validated."
                            placement="right-start"
                            arrow
                        >
                            <InfoCircleOutlined
                                style={{ color: "black", fontSize: "20px", marginLeft: '15px', marginRight: "20px", marginBottom: "10px" }}
                            />
                        </CustomTooltip>
                    </>
                )}
            </div>

{/*            { props.onboardingLocation && props.onboardingLocation.mediaPlanStat === "verified" && (
                <>
                    <h2 className="mb-4 ml-3 text-base mt-9">Active channels</h2>
                    { franchiseChannels.length > 0 && (
                        <>
                            <div className=" border border-[var(--color-primary)] border-solid">
                                { franchiseChannels.map((item, index) => (
                                    <div 
                                        className="flex items-center justify-start px-5 py-4"
                                        style={{backgroundColor: availableChannels.includes(item.title) ? 'var(--color-active)' : 'white'}}
                                    >
                                        <span className="text-base">{item.title}</span>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </>
            )}*/}
            
        </div>
    </>)
}

export default OnboardingMediaPlan;