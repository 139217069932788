import React, { useState, useEffect } from "react";
import CustomTooltip from "components/tooltip/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { selectUserAccount, UpdateUserBillingPreferences, updateUser, fetchUser } from "../../../store/Account/AccountStore";
import { InfoCircleOutlined } from "@ant-design/icons";
import { UserPaymentMethodsPage } from "components/payment/UserPaymentMethodsPage";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import CustomSwitch from "../../Switch/CustomSwitch";
import clsx from "clsx";

export const OnboardingBilling = () => {

    
    const account = useSelector(selectUserAccount);
    const whiteLabel = useSelector( selectWhiteLabel );
    const dispatch = useDispatch();
    const [selectedBillingMode, setSelectedBillingMode] = useState(account?.billingMode ? account?.billingMode : "" );
    const [autodraft, setAutoDraft] = useState(account?.autoDraft ? account?.autoDraft : false );
    const [showAlwaysOnTooltip, setShowAlwaysOnTooltip] = useState(false);

    const billingOptions = [
        { id: "payAsYouGo", value: "standard", label: "Pay as you go" },
        { id: "MonthlyBilling", value: "monthly", label: "Monthly billing" },
        { id: "BudgetApproval", value: "approval", label: "Budget approval" },
    ];

    useEffect(() => {
        dispatch(UpdateUserBillingPreferences({
            billingMode: selectedBillingMode,
            autoDraft: autodraft
        }));

    }, [selectedBillingMode, autodraft]);
    
    return ( <>
        <div className="billingMode">
            <div className="mb-5 flex items-center">
                <h1 className="text-lg">Billing mode</h1>
                <CustomTooltip
                open={showAlwaysOnTooltip}
                onMouseEnter={() => setShowAlwaysOnTooltip(true)}
                onMouseLeave={() => setShowAlwaysOnTooltip(false)}
                title="Choose a method of billing for all of your purchases. You can pay up front, receive a monthly bill, or set up approvals for a more controlled wokflow."
                placement="right-start"
                arrow
                >
                <InfoCircleOutlined
                    style={{
                    color: "#424242",
                    fontSize: "20px",
                    marginLeft: "15px",
                    marginRight: "20px",
                    marginBottom: "10px",
                    }}
                />
                </CustomTooltip>
            </div>

            <div className="radioBillingMode">
                {billingOptions.map((option) => (
                <BillingModeOption
                    key={option.id}
                    id={option.id}
                    value={option.value}
                    label={option.label}
                    selectedValue={selectedBillingMode}
                    onChange={setSelectedBillingMode}
                />
                ))}
            </div>

            <div className="draft mt-9">
                <div className="flex mb-4 items-center">
                    <CustomSwitch
                        marginLeft="0px"
                        onChange={ () => setAutoDraft(!autodraft) }
                        switchColor={whiteLabel.primaryColor}
                        checked={autodraft}
                    />
                    <div className="ml-3"></div>

                    <span className="text-lg"> Auto-draft </span>

                    <CustomTooltip
                        open={showAlwaysOnTooltip}
                        onMouseEnter={() => setShowAlwaysOnTooltip(true)}
                        onMouseLeave={() => setShowAlwaysOnTooltip(false)}
                        title="Save your card or link your bank account securely to enable recurring payments. Without auto-draft you will receive an invoice 30 days before payment is due."
                        placement="right-start"
                        arrow
                        >
                        <InfoCircleOutlined
                            style={{
                            color: "#424242",
                            fontSize: "20px",
                            marginLeft: "13px"
                            }}
                        />
                    </CustomTooltip>
                </div>

            </div>
        </div>

        <UserPaymentMethodsPage></UserPaymentMethodsPage>
    </>)
}

const BillingModeOption = ({
    id,
    value,
    label,
    selectedValue,
    onChange,
  }: {
    id: string;
    value: string;
    label: string;
    selectedValue: string;
    onChange: (value: string) => void;
  }) => {
    return (
      <div>
        <input
          type="radio"
          id={id}
          name="billingMode"
          value={value}
          className="hidden"
          checked={selectedValue === value}
          onChange={() => onChange(value)}
        />
        <label
          htmlFor={id}
          className="text-base flex items-center cursor-pointer"
          style={{ paddingBottom: '10px' }}
        >
          <div
            className={clsx(
              "w-[20px] h-[20px] border-2 border-solid rounded-full mr-2",
              selectedValue === value
                ? "bg-[var(--color-primary)] border-[var(--color-primary)]"
                : "bg-white border-[#AAAAAA]"
            )}
          ></div>
          <span>{label}</span>
        </label>
      </div>
    );
};

export default OnboardingBilling; 