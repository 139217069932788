import { useState } from 'react'
import {OnboardingOutlets} from './OnboardingOutlets'
import {OnboardingSystem} from './OnboardingSystem'

import { ReactComponent as IconArrow } from "../../../assets/icons/arrow-down-black.svg";

export const DistributionMain = () => {
    
    const [accordionIndex, setAccordionIndex] = useState(null as null | number);

    const accordionItems = [
        {
          name: 'Outlets',
          component: <OnboardingOutlets/>,
        },
        {
          name: 'System access',
          component: <OnboardingSystem/>,
        },
    ];

    const toggleAccordion = (index : number | null) => {
        setAccordionIndex(accordionIndex === index ? null : index); // Toggle the accordion
    };

    return ( <>
        <h1 className='mb-6 text-2xl'>Distribution</h1>
        <div className="space-y-8">
            {accordionItems.map((item, index) => (
                <div key={index} className="">
                    <button
                        onClick={() => toggleAccordion(index)}
                        className="flex items-center justify-between w-5/6 p-2 bg-white rounded-2xl hover:bg-gray-100 focus:outline-none px-7"
                        style={{ borderWidth: '1px', borderColor: 'black' }}
                    >
                        
                        <span className='text-lg'>{item.name}</span>

                        <span
                            className='flex items-center'
                            style={{
                                transform: accordionIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                            }}
                        >
                            <IconArrow />
                        </span>
                        
                    </button>
                    {accordionIndex === index && (
                        <div className="w-full py-6">{item.component}</div>
                    )}
                </div>
            ))}
        </div>
    </>)
}

export default DistributionMain;