import { OnboardingLocation } from "../models/OnboardingLocation";
import RestService from "./restService";

export default class OnboardingLocationService {
  constructor(protected readonly api: RestService) {}

  async getOnboardingLocations(): Promise<OnboardingLocation[]> {
    return await this.api.get<OnboardingLocation[]>(`/onboarding-locations`);
  }

}
