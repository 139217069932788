import CustomTooltip from "../../tooltip/tooltip";
import React, { useState, useEffect } from "react"
import { Button } from "antd";
import { useSelector } from 'react-redux';
import { InfoCircleOutlined } from "@ant-design/icons";
import { selectOnboardingFranchise, selectIsOnboardingFranchiseLoaded } from "../../../store/OnboardingFranchise/OnboardingFranchiseStore";
import { useNavigate } from "react-router-dom";

export const OnboardingOutlets = () => {
    const navigate = useNavigate();
    
    const [showAlwaysOnTooltip, setShowAlwaysOnTooltip] = useState(false);
    
    const onboardingFranchise = useSelector( selectOnboardingFranchise );
    const isLoaded = useSelector( selectIsOnboardingFranchiseLoaded );

    return ( <>
        <div className="flex items-end gap-x-12">

            { onboardingFranchise && onboardingFranchise.locationsStat && onboardingFranchise.locationsStat === "verified" ? (
                
                <>
                    <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                        <span>Verified</span>
                        <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#009051' }}></span>
                    </div>

                    <div 
                        onClick={() => navigate("/location-manager")}
                        className="primary-button w-[auto] pointer-hover h-full px-4"
                    >
                        <div className="primary-button-text">MANAGE OUTLETS</div>
                    </div>
        
                </>
                
                
            ): (
                <>
                    <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                        <span>Pending</span>
                        <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#FF5566' }}></span>
                    </div>

                    <CustomTooltip
                        open={showAlwaysOnTooltip}
                        onMouseEnter={() => setShowAlwaysOnTooltip(true)}
                        onMouseLeave={() => setShowAlwaysOnTooltip(false)}
                        title="Upload your outlets through the chat assistant or by using the new location form in the tools module. If your outlets have been uplaoded this check will clear when all information is validated."
                        placement="right-start"
                        arrow
                    >
                        <InfoCircleOutlined
                            style={{ color: "black", fontSize: "20px", marginLeft: '15px', marginRight: "20px", marginBottom: "10px" }}
                        />
                    </CustomTooltip>
                </>
            )}
            

            
        </div>
    </>)
}

export default OnboardingOutlets;