import "./UserPaymentMethods.css";
import { useSelector } from "react-redux";
import { selectUserAccount } from "../../store/Account/AccountStore";
import { SavedPaymentCard } from "./SavedPaymentCard";
import { Button } from "antd";
import { useState } from "react";
import { AddCardForm } from "./AddCardForm";
import { AddCardACHForm } from "./AddACHForm";
import { selectWhiteLabel } from "../../store/WhiteLabel/WhiteLabelStore";

export const UserPaymentMethodsPage = (): JSX.Element => {
  const account = useSelector(selectUserAccount);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isACH, setIsACH] = useState(false);

  const whiteLabel = useSelector( selectWhiteLabel );


  return (
    <div className={`${classNames.container} mt-12 `}>
      
      
      {account && (
        <>
          {!isFormVisible ? (
            <>
              {!isFormVisible ? <h1 className="text-lg mb-3">Saved cards</h1> : <h1 className="text-lg mb-3">New card</h1>}
              <div className="grid grid-cols-4">

                {account.paymentMethods?.map((method) => (
                  method.type == "card" &&
                    <SavedPaymentCard
                      method={method}
                      key={`saved method card: ${method.id}`} 
                    /> 
                ))}
                
                <div className="flex justify-start col-span-3">
                  <Button
                    style={{ backgroundColor: whiteLabel.primaryColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    className="primary-button pointer-hover  w-auto p-[8px_47px] hover:text-white"
                    onClick={() => setIsFormVisible(true)}
                  >
                    <div className="primary-button-text">ADD</div>
                  </Button>
                </div>

              </div>

              

              {!isFormVisible && <h1 className="text-lg mb-3 mt-14">Linked bank accounts</h1> }
              <div className="grid grid-cols-4">
                {account.paymentMethods?.map((method) => (
                  method.type == "bank" &&
                    <SavedPaymentCard
                      method={method}
                      key={`saved method card: ${method.id}`} 
                    /> 
                ))}

                <div className="flex justify-start col-span-3">
                  <Button
                    style={{ backgroundColor: whiteLabel.primaryColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    className="primary-button pointer-hover  w-auto p-[8px_47px] hover:text-white"
                    onClick={() => {
                      setIsFormVisible(true);
                      setIsACH(true);
                    }}
                  >
                    <div className="primary-button-text">ADD</div>
                  </Button>
                </div>
              </div>
            
            </>
          ) : (

            isACH ? 
              <AddCardACHForm onClose={() => {
                setIsFormVisible(false)
                setIsACH(false);
              }} />
            :  
              <AddCardForm onClose={() => setIsFormVisible(false)} showBackButton={true}/>                      
          )}
        </>
      )}
    </div>
  );
};

const classNames = {
  container: "user-payment-methods-page-container",
};
