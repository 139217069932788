import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Locations from "../../components/purchase/locations";
import Layout from "../../components/dashboard";
import { DashboardContent } from "../../components/dashboard/DashboardContent";
import { EventCalendarPage } from "../../components/eventCalendar/EventCalendarPage";
import { AvailableRoutes } from "../Routes";
import "./styles.css";
import AddCampaignFlowLayout from "../../components/Layout/AddCampaignFlowLayout";
import TargetZone from "../../components/purchase/targetZone/TargetZone";
import Channels from "../../components/purchase/channels/Channel";
import ChannelTemplates from "../../components/purchase/channels/ChannelTemplates";
import ScheduleCampaign from "../../components/purchase/schedule/ScheduleCampaign";
import ReviewAndPay from "../../components/purchase/reviewAndPay/ReviewAndPay";
import Confirmation from "../../components/purchase/confirmation/Confirmation";
import ConfirmationLayout from "../../components/purchase/confirmation/ConfirmationLayout";
import { ReportsPage } from "../../components/reports/ReportsPage";
import { ReportsSinglePage } from "../../components/reports/ReportsSinglePage";
import { fetchChannels, fetchReportChannels } from "../../store/Channels/ChannelStore";
import { useDispatch, useSelector } from "react-redux";
import { fetchFranchiseLocations, fetchLocations, selectFetchLocationsLoaded, selectFranchiseLocations } from "../../store/Location/LocationStore";
import { UserPaymentMethodsPage } from "../../components/payment/UserPaymentMethodsPage";
import AccountInformation from "../../components/account/AccountInformation";
import ChangePassword from "../../components/account/ChangePassword";
import BillingAddress from "../../components/account/BillingAddress";
import AccountLayout from "../../components/account/AccountLayout/AccountLayout";
import LocationManager from "../../components/locationManager";
import CampaignHistory from "../../components/account/CampaignHistory";
import ManageEvents from "../../components/account/ManageEvents";
import { selectUserAccount } from "../../store/Account/AccountStore";
import { fetchPackages } from "../../store/customPackages/CustomPackagesStore";
import { fetchTemplates, fetchTemplatesForEachChannel } from "../../store/CreativeTemplates/CreativeTemplateStore";
import MediaTemplates from "../../components/account/MediaTemplates/index";
import SystemTools from "../../components/account/SystemTools";
import QRPromGenerator from "../../components/qrGenerator/qrPromGenerator";
import QRURLGenerator from "../../components/qrGenerator/qrURLGenerator";
import OmniChannel from "../../components/reports/OmniChannel/OmniChannel";
import { ReportsLanding } from "../../components/reports/ReportsLanding";
import Billing from "components/account/Billing";
import {FeedPage} from "../../components/feed/FeedPage";
import { CustomEvents } from "components/account/CustomEvents";
import { KeyWordsCreative } from "components/reports/KeyWordsCreative";
import { KeywordHelperPage } from "../../components/keywordHelper/KeywordHelperPage";
import { SpendPacing } from "../../components/reports/SpendPacing";
import ActiveOrders from "../../components/account/ActiveOrders";
import BudgetTrackerPage from "../../components/budgetTracker/BudgetTrackerPage";
import { selectFranchise } from "../../store/Franchise/FranchiseStore";
import services from "../../services/index";
import BudgetTrackerApprovals from "components/budgetTracker/budgetTrackerApprovals/BudgetTrackerApprovals";
import { OnboardingMainPage } from "../../components/onboarding/OnboardingMain";

const MainApp: React.FC = () => {
  const dispatch = useDispatch();
  const account = useSelector(selectUserAccount);
  const locations = useSelector(selectFranchiseLocations);
  const franchise = useSelector(selectFranchise);
  const fetchLocationsLoaded = useSelector(selectFetchLocationsLoaded);

  const [hasPushedChatServiceData, setHasPushedChatServiceData] = useState(false);

  useEffect(() => {
    dispatch(fetchChannels({ id: "accountId" }));
    dispatch(fetchReportChannels({ id: "accountId" }));
    dispatch(fetchPackages());
    dispatch(fetchTemplates());
    dispatch(fetchTemplatesForEachChannel());
    dispatch(fetchLocations());
    if (account) {
      dispatch(fetchFranchiseLocations(account.franchiseId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  /**
   * When all the data is available, then push into the dataLayer all
   * the information. This should only execute once.
  */
  useEffect(() => {
    if( !hasPushedChatServiceData && account && franchise && fetchLocationsLoaded ){
      console.log('All the data is available. Pushing into the DataLayer.')
      services.dataLayerService.registerChatServiceData(account, locations, franchise);
      setHasPushedChatServiceData(true);
    }
  },[account, locations, franchise]);

  return (
    <div className="container">
      <Routes>
        <Route
          path={AvailableRoutes.ACTIVE_CAMPAIGNS}
          element={
            <Layout>
              <ReportsPage />
            </Layout>
          }
        />
        <Route
          path={AvailableRoutes.REPORT}
          element={
            <Layout>
              <ReportsSinglePage />
            </Layout>
          }
        />
        <Route
          path={AvailableRoutes.PERFORMANCE_OVERVIEW}
          element={
            <Layout>
              <OmniChannel />
            </Layout>
          }
        />
        <Route
          path={AvailableRoutes.REPORTS_LANDING}
          element={
            <Layout>
              <ReportsLanding />
            </Layout>
          }
        />
        <Route
          path={AvailableRoutes.SPEND_PACING}
          element={
            <Layout>
              <SpendPacing />
            </Layout>
          }
        />
        <Route
          path={AvailableRoutes.KEYWORDS_CREATIVE}
          element={
            <Layout>
              <KeyWordsCreative />
            </Layout>
          }
        />
        <Route
          path={AvailableRoutes.EVENT}
          element={
            <Layout>
              <EventCalendarPage />
            </Layout>
          }
        />
        <Route
          path={AvailableRoutes.KEYWORD_HELPER}
          element={
            <Layout>
              <KeywordHelperPage />
            </Layout>
          }
        />
        <Route
          path={AvailableRoutes.BUDGET_TRACKER}
          element={
            <Layout>
              <BudgetTrackerPage />
            </Layout>
          }
        />
        <Route
          path={AvailableRoutes.BUDGET_TRACKER_APPROVALS}
          element={
            <Layout>
              <BudgetTrackerApprovals />
            </Layout>
          }
        />
        <Route
          path={AvailableRoutes.LOCATIONS}
          element={
            <AddCampaignFlowLayout>
              <Locations />
            </AddCampaignFlowLayout>
          }
        />
        <Route
          path={AvailableRoutes.TARGET_ZONE}
          element={
            <AddCampaignFlowLayout>
              <TargetZone />
            </AddCampaignFlowLayout>
          }
        />
        <Route
          path={AvailableRoutes.CHANNELS}
          element={
            <AddCampaignFlowLayout>
              <Channels />
            </AddCampaignFlowLayout>
          }
        />
        <Route
          path={AvailableRoutes.TEMPLATES}
          element={
            <AddCampaignFlowLayout>
              <ChannelTemplates />
            </AddCampaignFlowLayout>
          }
        />
        <Route
          path={AvailableRoutes.SCHEDULE}
          element={
            <AddCampaignFlowLayout>
              <ScheduleCampaign />
            </AddCampaignFlowLayout>
          }
        />
        <Route
          path={AvailableRoutes.REVIEW}
          element={
            <AddCampaignFlowLayout>
              <ReviewAndPay />
            </AddCampaignFlowLayout>
          }
        />
        <Route
          path={AvailableRoutes.CONFIRMATION}
          element={
            <ConfirmationLayout>
              <Confirmation />
            </ConfirmationLayout>
          }
        />
        <Route
          path={AvailableRoutes.ACCT_INFO}
          element={
            <AccountLayout>
              <AccountInformation />
            </AccountLayout>
          }
        />
        <Route
          path={AvailableRoutes.CHANGE_PASSWORD}
          element={
            <AccountLayout>
              <ChangePassword />
            </AccountLayout>
          }
        />
        <Route
          path={AvailableRoutes.BILLING_ADDRESS}
          element={
            <AccountLayout>
              <BillingAddress showTitle={true} />
            </AccountLayout>
          }
        />
        <Route
          path={AvailableRoutes.LOCATION_MANAGER}
          element={
            <Layout>
              <LocationManager />
            </Layout>
          }
        />
        <Route
          path={AvailableRoutes.PAYMENT_METHODS}
          element={
            <AccountLayout>
              <UserPaymentMethodsPage />
            </AccountLayout>
          }
        />
        <Route
          path={AvailableRoutes.CAMPAIGN_HISTORY}
          element={
            <AccountLayout>
              <CampaignHistory />
            </AccountLayout>
          }
        />
        <Route
          path={AvailableRoutes.BILLING}
          element={
            <AccountLayout>
              <Billing />
            </AccountLayout>
          }
        />
        <Route
          path={AvailableRoutes.MANAGE_EVENTS}
          element={
            <AccountLayout>
              <ManageEvents />
            </AccountLayout>
          }
        />
        <Route
          path={AvailableRoutes.ACTIVE_ORDERS}
          element={
            <AccountLayout>
              <ActiveOrders />
            </AccountLayout>
          }
        />
        <Route
          path={AvailableRoutes.CUSTOM_EVENTS}
          element={
            <AccountLayout>
              <CustomEvents />
            </AccountLayout>
          }
        />
        <Route
          path={AvailableRoutes.SYSTEM_TOOLS}
          element={
            <AccountLayout>
              <SystemTools />
            </AccountLayout>
          }
        />
        <Route
          path={AvailableRoutes.PERFORMANCE_OVERVIEW}
          element={
            <AccountLayout>
              <OmniChannel />
            </AccountLayout>
          }
        />
        <Route
          path={AvailableRoutes.QR_PROM_GEN}
          element={
            <AccountLayout>
              <QRPromGenerator />
            </AccountLayout>
          }
        />
        <Route
          path={AvailableRoutes.QR_URL_GEN}
          element={
            <AccountLayout>
              <QRURLGenerator />
            </AccountLayout>
          }
        />
        <Route
          path="/onboarding/:section"
          element={
            <Layout>
              <OnboardingMainPage/>
            </Layout>
          }
        />
        <Route
          path="*"
          element={
            <Layout>
              <FeedPage/>
            </Layout>
          }
        />
      </Routes>
    </div>
  );
};

export default MainApp;
