import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import { AvailableRoutes } from "../../../pages/Routes";
import {
  saveTargetZone,
  selectAgreedToTerms,
  selectAlertAgreedToTerms,
  setAlertAgreedToTerms,
  selectAlwaysOn,
  selectAdjustedSpendPercent,
  selectCurrentEstimatedCost,
  // selectIsZoneSelected,
  selectNewCampaignChannels,
  selectNewCampaignCustomPackage,
  selectPaymentMethod,
  selectSavedTargetZones,
  selectSelectedLocation,
  selectTotalArea,
  selectTotalAudience,
  setIsZoneSelected,
  setSingleLocation,
  selectSingleLocation,
  selectDetailsLocation,
  setDetailsLocation,
  selectHandleCreateAudience,
  selectPreviewAudience,
  selectTargetZoneChanged,
  selectSelectedChannels,
  selectZipModeEnabled,
  selectPrimaryZips,
  selectSecondaryZips,
  selectIsBudgetModeFixed,
  selectedMonth,
  selectAudienceSegment,
  selectNewBudget,
  selectMonthlyBudget
} from "../../../store/campaignForm/CampaignForm"
import ChannelSidebar from "../channels/ChannelSidebar";
import "./styles.css";
import { TargetZoneSidbar } from "../targetZone/TargetZoneSidbar";
import ScheduleSidebar from "../schedule/ScheduleSidebar";
import ReviewAndPaySidebar from "../reviewAndPay/ReviewAndPaySidebar";
import {
  createAdCampaign,
  selectCreateCampaignLoading,
  selectNewAdCampaign,
} from "../../../store/AdCampaigns/AdCampaignStore";
import { Account } from "../../../models/Account";
import { CircularProgress } from "@mui/material";
import { CountryEnum } from "../../../models/Location";
import { ChannelType } from "../../../models/Channel";
import LocationDetailsSide from "./LocationDetailsSide";
import { Button } from "antd";
import AccountSelection from "./AccountSelection";
import { getMonthIndexByCode } from "../../../models/MonthlyBudget";
import { PersistGate } from 'redux-persist/es/integration/react'
import { Provider } from "react-redux";
import { store, persistor } from "../../../store/store";
import { selectUserAccount } from "../../../store/Account/AccountStore";

const LocationSideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedLocation = useSelector(selectSelectedLocation);
  const selectedChannels: ChannelType[] = useSelector(selectSelectedChannels);
  const newChannels = useSelector(selectNewCampaignChannels);
  const newPackage = useSelector(selectNewCampaignCustomPackage);
  const audience = useSelector(selectTotalAudience);
  const area = useSelector(selectTotalArea);
  const estimatedCost = useSelector(selectCurrentEstimatedCost);
  const newCampaign = useSelector(selectNewAdCampaign);
  const paymentId = useSelector(selectPaymentMethod);
  const savedZones = useSelector(selectSavedTargetZones);
  // const isZonesSaved = useSelector(selectIsZoneSelected);
  const adjustedSpendPercent = useSelector(selectAdjustedSpendPercent);
  const alwaysOn = useSelector(selectAlwaysOn);
  const acceptedTerms = useSelector(selectAgreedToTerms);
  const alertAgreeded = useSelector(selectAlertAgreedToTerms);
  const paymentMethod = useSelector(selectPaymentMethod);
  const createLoading = useSelector(selectCreateCampaignLoading);
  const singleLocation = useSelector(selectSingleLocation);
  const detailsLocation = useSelector(selectDetailsLocation);
  const handleCreateAudience = useSelector(selectHandleCreateAudience);
  const previewAudience = useSelector(selectPreviewAudience);
  const targetZoneChanged = useSelector(selectTargetZoneChanged);
  const usesZipMode = useSelector(selectZipModeEnabled)
  const selectedPrimaryZips = useSelector(selectPrimaryZips)
  const selectedSecondaryZips = useSelector(selectSecondaryZips)
  const isFixedBudgetMode = useSelector(selectIsBudgetModeFixed);
  const budgetMonth = useSelector(selectedMonth);
  const audienceSegment = useSelector(selectAudienceSegment);
  const newBudget = useSelector(selectNewBudget);
  const monthlyBudget = useSelector(selectMonthlyBudget);
  const account = useSelector(selectUserAccount);

  useEffect(() => {
    if (newCampaign) {
      navigate(AvailableRoutes.CONFIRMATION);
    }
  }, [newCampaign]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (singleLocation && location.pathname === AvailableRoutes.LOCATIONS) {
      handleNext();
      setSingleLocation(false);
    }
  }, [singleLocation]);

  const handleNext = (): void => {
    if(!selectedChannels.length && renderNextButtonTitle() === "SCHEDULE") return;
    dispatch(setDetailsLocation(null));
    if (createLoading) {
      return;
    }
    switch (location.pathname) {
      case AvailableRoutes.TARGET_ZONE:
        if( !usesZipMode ){
          if (  savedZones.length > 0) navigate(AvailableRoutes.CHANNELS);
          else dispatch(setIsZoneSelected(false));
        }else{
          if ( selectedPrimaryZips.length > 0 || selectedSecondaryZips.length > 0 ) navigate(AvailableRoutes.CHANNELS);
        }

        break;
      case AvailableRoutes.CHANNELS:
        if( isFixedBudgetMode ){
          if( !monthlyBudget ) break;
          const budgetForMonth = monthlyBudget[getMonthIndexByCode(budgetMonth)];
          if( budgetForMonth.isDefined === false && !newBudget ){
            console.log('New budget is not defined');
            break;
          } 
        }
        console.log(newBudget);
        navigate(AvailableRoutes.SCHEDULE);
        break;
      case AvailableRoutes.SCHEDULE:
        navigate(AvailableRoutes.REVIEW);
        break;
      case AvailableRoutes.REVIEW:
        console.log(audience);
        console.log(area);
        console.log(paymentMethod)
        if(
          selectedLocation !== null &&
          acceptedTerms &&
          paymentMethod !== "" &&
          paymentMethod !== "newCard" &&
          account
        ){

          //By default assume a new budget is not being created.
          //Then check if the current purschase is using fixed budget mode, and also check if a new budget is actually chosen.
          let isNewBudget = false;
          if( isFixedBudgetMode && paymentMethod === "preapproved" ){
            if( !monthlyBudget ) break;
            const budgetForMonth = monthlyBudget[getMonthIndexByCode(budgetMonth)];
            if( budgetForMonth.isDefined === false ){
              isNewBudget = true;
            }
          }

          //obtains the billing mode and the costs based on the payment method and user billing mode.
          const costInDollars = paymentMethod === "preapproved" ? 0 : Number(estimatedCost.toFixed(2));
          let billingMode;
          if( paymentMethod === "preapproved"){
            billingMode = "approval";
          }else if(paymentMethod === "monthly"){
            billingMode = "monthly";
          }else{
            billingMode = "standard";
          }

          if( usesZipMode){
            console.log("zip mode")
            //Request the campaign creation using ZIP codes.
            //This will ignore audienceSize, aureaInMiles and zones.
            if (
              selectedPrimaryZips.length > 0 ||
              selectedSecondaryZips.length > 0
            ) {
              const combinedZips = selectedPrimaryZips.concat(selectedSecondaryZips);
              dispatch(
                createAdCampaign({
                  account: { id: "acctId" } as Account,
                  newCampaignChannels: newChannels,
                  newPackages: newPackage ? [newPackage] : [],
                  audienceSize: 0,
                  areaInMiles: 0,
                  locationIds: [selectedLocation.id],
                  costInDollars: costInDollars,
                  cardId: paymentId,
                  zones: [],
                  adjustedSpendPercent: adjustedSpendPercent / 100,
                  alwaysOn: alwaysOn,
                  zips: combinedZips,
                  month: budgetMonth,
                  segments: audienceSegment,
                  isNewBudget: isNewBudget,
                  newBudget: newBudget,
                  billingMode: billingMode
                })
              );
            }
          }else{
            console.log("no zip mode")

            //Request the campaign creation using "draft" or zones.
            //This will ignore the primary and secondary zips.
            if (
              audience > 0 &&
              area !== null
            ) { 

              dispatch(
                createAdCampaign({
                  account: { id: "acctId" } as Account,
                  newCampaignChannels: newChannels,
                  newPackages: newPackage ? [newPackage] : [],
                  audienceSize: audience,
                  areaInMiles: area,
                  locationIds: [selectedLocation.id],
                  costInDollars: costInDollars,
                  cardId: paymentId,
                  zones: savedZones,
                  adjustedSpendPercent: adjustedSpendPercent / 100,
                  alwaysOn: alwaysOn,
                  zips: [],
                  month: budgetMonth,
                  segments: audienceSegment,
                  isNewBudget: isNewBudget,
                  newBudget: newBudget,
                  billingMode: billingMode
                })
              );
            }
          }
        }else{
          if (!acceptedTerms) {
            dispatch(setAlertAgreedToTerms(true))
          }
        }
        break;
      case AvailableRoutes.LOCATIONS:
      default:
        if (selectedLocation !== null) {
          // no demographic info for Canadian Addresses, will skip map step
          if (selectedLocation.country !== CountryEnum.USA) {
            dispatch(
              saveTargetZone({
                area: 49.91,
                audience: 216288,
                center: { lat: 49.0952, lng: -123.0264 },
                radiusInMiles: 10,
                //selectors: []
              })
            );
            navigate(AvailableRoutes.CHANNELS);
          } else {
            navigate(AvailableRoutes.TARGET_ZONE);
          }
        }
        break;
    }
  };

  const renderNextButtonTitle = (): string => {
    switch (location.pathname) {
      case AvailableRoutes.TARGET_ZONE:
        return "CHOOSE CAMPAIGN TYPE";
      case AvailableRoutes.TEMPLATES:
      case AvailableRoutes.CHANNELS:
        return "SCHEDULE";
      case AvailableRoutes.SCHEDULE:
        return "REVIEW & PAY";
      case AvailableRoutes.REVIEW:
        return "PLACE ORDER";
      case AvailableRoutes.LOCATIONS:
      default:
        return "DEFINE YOUR AUDIENCE";
    }
  };

  const renderSidebarContent = () => {
    switch (location.pathname) {
      case AvailableRoutes.TARGET_ZONE:
        return <TargetZoneSidbar />;
      case AvailableRoutes.TEMPLATES:
      case AvailableRoutes.CHANNELS:
        return <ChannelSidebar />;
      case AvailableRoutes.SCHEDULE:
        return <ScheduleSidebar />;
      case AvailableRoutes.REVIEW:
        return <ReviewAndPaySidebar />;
      case AvailableRoutes.LOCATIONS:
      default:
        return (
          <div className="sidebar-location-select-text">
            <AccountSelection />
            {detailsLocation && selectedLocation && <div style={{ marginTop: '16px' }}>
              <LocationDetailsSide location={detailsLocation} />
            </div>}
          </div>
        );
    }
  };

  return (
    <div className="checkout-sidebar-container">
      <Provider store={store}>
      <PersistGate persistor={persistor}>
      <div className="sidebar-dashboard">
        <Button
          className={`sidebar-dashboard-button 
          ${(!selectedChannels.length && renderNextButtonTitle() === "SCHEDULE") ? 'sidebar-dashboard-button-disabled' : null}`
          }
          onClick={handleNext}
          disabled={
            (location.pathname === AvailableRoutes.TARGET_ZONE && !usesZipMode && savedZones.length < 1) ||
            (location.pathname === AvailableRoutes.TARGET_ZONE && usesZipMode && (selectedPrimaryZips.length < 1 && selectedSecondaryZips.length < 1)) ||
            (location.pathname === AvailableRoutes.TEMPLATES)
          }
          onFocus={(e) => e.target.blur()}
        >
          {createLoading ? (
            <CircularProgress sx={{ color: "white" }} />
          ) : (
            <>
              <div className="sidebar-dashboard-button-text">
                {renderNextButtonTitle()}
              </div>
              <IconArrowRight />
            </>
          )}
        </Button>
        {
          (location.pathname === AvailableRoutes.TARGET_ZONE && !usesZipMode)
        && (
          <Button
            className="sidebar-inverted-button"
            onClick={handleCreateAudience}
            disabled={!targetZoneChanged}
          >
            <span className="sidebar-inverted-button-text">
              CREATE AUDIENCE
            </span>
          </Button>
        )}
      </div>
      {renderSidebarContent()}
      </PersistGate>
      </Provider>
    </div>
  );
};

export default React.memo(LocationSideBar);
