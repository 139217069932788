import React, {useEffect, useState} from "react";
import "./styles.css";
import DashboardSideBar from "./sidebar";
import HeaderLayout from "../Layout/HeaderLayout";
import { AvailableRoutes } from "../../pages/Routes";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as IconMore } from "../../assets/icons/more-vertical.svg";
import { useSelector } from "react-redux";
import { selectWhiteLabel } from "../../store/WhiteLabel/WhiteLabelStore";
import { assetImage } from "../../utility/whiteLabelManager";
import { selectSelectedMainMenu, selectSelectedSubMenu, selectShowActivitySideBar } from "store/Navigation/NavigationStore";
import { ActiveChannelDashBoard } from "../feed/FeedSidebar";
import { OnboardingSidebar } from "../../components/onboarding/OnboardingSidebar";
import services from "../../services";
import { selectUserAccount } from "../../store/Account/AccountStore"
import { selectFranchiseLocations } from "../../store/Location/LocationStore";
import { selectFranchise } from "../../store/Franchise/FranchiseStore";

const LayoutDashboard = ({ children }: any) => {
  const navigate = useNavigate();
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const whiteLabel = useSelector( selectWhiteLabel );
  const selectedOption = useSelector( selectSelectedMainMenu );
  const selectedSubmenu = useSelector( selectSelectedSubMenu );
  const showActivitySideBar = useSelector( selectShowActivitySideBar );

  const account = useSelector(selectUserAccount);
  const locations = useSelector(selectFranchiseLocations);
  const franchise = useSelector(selectFranchise);


  // to control the sideBar on the left when activity view is on screen
  const [isOpen, setIsOpen] = useState(true);
  const OpenChannelsSideBar = (val: boolean) => {
    setIsOpen(!val)
  }

  // end 


  useEffect(() => {
    services.dataLayerService.registerPageView();
    
  });

  useEffect( () => {
    setIsOpen(true);
  }, [selectedOption] )



  return (
    <div>
      <HeaderLayout>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <img
            className="header-logo"
            onClick={() => navigate(AvailableRoutes.ALERT)}
            src={ assetImage( whiteLabel.secondaryLogoPath ) }
            alt=""
          />
        </div>
      </HeaderLayout>


      <div className={`content-body res:grid-cols-1  res:pb-[170px] ${(showActivitySideBar ) ? 'activeNewArea ' : ' '} ${isOpen ? "openChannelSideBar" : "" }`}>
        {(   showActivitySideBar && selectedSubmenu === "insights" )  && (
            <ActiveChannelDashBoard OpenChannelsSideBar={OpenChannelsSideBar} isOpen={isOpen} />
        )}

        {( showActivitySideBar && selectedSubmenu === "onboarding" )  && (
            <OnboardingSidebar OpenOnboardingSideBar={OpenChannelsSideBar} isOpen={isOpen} />
        )}


        <div className={` content-body-content ${showActivitySideBar ? " h-auto flex justify-center" : " h-[100%]  " } `}>{children}</div>
        <div className="dashboard-sidebar res:fixed res:w-full res:z-[99] res:bottom-0 res:max-h-[50%] res:overflow-scroll">
          <DashboardSideBar />
        </div>

        {/* <div className="dashboard-mobile-sidebar ">
          <div className="mobile-sidebar-button">
            <div className="mobile-button-background">
              <div
                className="sidebar-dashboard-button pointer-hover"
                onClick={() => navigate(AvailableRoutes.LOCATIONS)}
              >
                <div className="sidebar-dashboard-button-text">
                  PLAN MY CAMPAIGN
                </div>
                <IconArrowRight />
              </div>
              <IconMore
                className="mobile-more-icon"
                onClick={() => setShowMobileSidebar(!showMobileSidebar)}
              />
            </div>
          </div>
        </div> */}

        {showMobileSidebar && (
          <div className="mobile-sidebar-container">
            <div
              className="mobile-sidebar-oppacity"
              onClick={() => setShowMobileSidebar(false)}
            ></div>
            <div className="mobile-sidebar">
              <DashboardSideBar />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(LayoutDashboard);
