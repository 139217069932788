import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { IMonthlyBudgetDetail } from "../../models/MonthlyBudget"; 
import { RootState } from "../index";
import { NewCampaignChannel } from "../../models/CampaignChannel";
import { ChannelType } from "../../models/Channel";
import { Location } from "../../models/Location";
import { TargetZone } from "../../models/TargetZone";
import { polygonsHaveMatchingPoints } from "../../utility/geography";
import { CustomPackage } from "../../models/CustomPackage";
import { NewCampaignCustomPackage } from "../../models/CampaignPackage";
import { CreativeTemplate } from "../../models/CreativeTemplate";
import { Audience } from "../../models/Audience";
import {
  addDaysToDate,
  getHoursBetween2Dates,
  HOURS_IN_A_WEEK,
  HOURS_IN_DAY,
  STANDARD_BILLING_PERIOD_WEEKS,
} from "../../utility/time";
import services from "../../services";

export interface LatLonPair {
  lat: number;
  lng: number;
}

/**
 * Fetch audiences
 */
export const fetchAudiences = createAsyncThunk("franchise/audiences", async () => {
  const audiences = await services.franchiseService.getAudiences();
  return audiences;
});

export const fetchMonthlyBudget = createAsyncThunk("user/budget", async (locationId: string) => {
  const budget = await services.userService.getMonthlyBudget(locationId);
  return budget;
});

const initialState = {
  selectedLocation: null as Location | null,
  detailsLocation: null as Location | null,
  targetZonePoints: [] as LatLonPair[],
  zoneRadius: 0 as number, // in meters!
  negativeTargetZonePoints: [] as LatLonPair[],
  negativeZoneRadius: 0 as number, // in meters!
  isNegativeZone: false as boolean,
  SelectedChannels: [] as ChannelType[],
  newCampaignChannels: [] as NewCampaignChannel[],
  selectedCustomPackage: null as CustomPackage | null,
  newCampaignCustomPackage: null as NewCampaignCustomPackage | null,
  newCampaignCustomPackages: [] as NewCampaignCustomPackage[],
  templateTitle: "" as string,
  templateChannel: null as ChannelType | null,
  alwaysOn: true as boolean,
  setSpend: null as number | null,
  isSetSpend: false as boolean,
  adjustedSpendPercent: 100 as number,
  paymentMethod: "" as string,
  view: false as boolean,
  displayCrosswalkOverlay: false,
  displayRedemptionsOverlay: false,
  savedTargetZones: [] as TargetZone[],
  savedPreviewArea: 0 as number,
  savedPreviewAudience: 0 as number,
  isCustomTargetArea: false as boolean, // true if using custom area, false for point with radius
  isNegativeCustomTargetArea: false as boolean, // true if using custom area, false for point with radius
  isZoneSelected: true as boolean,
  agreedToTerms: false as boolean,
  alertAgreedToTerms: false as boolean,
  selectedTemplates: [] as CreativeTemplate[],
  preSelectedTemplates: [] as CreativeTemplate[],
  singleLocation: false as boolean,
  handleCreateAudience: () => { },
  targetZoneChanged: true as boolean,
  selectableLocations: "USER" as string,
  audiences: [] as Audience[],
  zipModeEnabled: true,
  primaryZips: [] as string[],
  secondaryZips: [] as string[],
  fixedBudgetMode: true as boolean,
  avgChannelCostPercent: 0 as number,
  monthlyBudget: null as IMonthlyBudgetDetail[] | null,
  newBudget: 0 as number ,
  selectedMonth: "" as string,
  audienceSegment: [] as string[],
};

export const campaignSlice = createSlice({
  name: "campaignForm",
  initialState,
  reducers: {
    resetNewCampaignForm: (state, { payload }: PayloadAction<undefined>) => {
      return initialState;
    },
    setSelectedLocation: (
      state,
      { payload: selectedLocation }: PayloadAction<Location | null>
    ) => {
      state.savedTargetZones = [];
      state.primaryZips = [];
      state.secondaryZips = [];
      state.selectedLocation = selectedLocation;
    },
    setDetailsLocation: (
      state,
      { payload: detailsLocation }: PayloadAction<Location | null>
    ) => {
      state.detailsLocation = detailsLocation;
    },
    setZoneRadius: (state, { payload: zoneRadius }: PayloadAction<number>) => {
      state.zoneRadius = zoneRadius;
    },
    setTargetZonePoints: (
      state,
      { payload: points }: PayloadAction<LatLonPair[]>
    ) => {
      state.targetZonePoints = points;
    },
    setNegativeZoneRadius: (
      state,
      { payload: zoneRadius }: PayloadAction<number>
    ) => {
      state.negativeZoneRadius = zoneRadius;
    },
    setNegativeTargetZonePoints: (
      state,
      { payload: points }: PayloadAction<LatLonPair[]>
    ) => {
      state.negativeTargetZonePoints = points;
    },
    setIsNegativeZone: (
      state,
      { payload: isNegativeZone }: PayloadAction<boolean>
    ) => {
      state.isNegativeZone = isNegativeZone;
    },
    addTargetZonePoint: (
      state,
      { payload: point }: PayloadAction<LatLonPair>
    ) => {
      state.targetZonePoints.push(point);
    },
    addNegativeTargetZonePoint: (
      state,
      { payload: point }: PayloadAction<LatLonPair>
    ) => {
      state.negativeTargetZonePoints.push(point);
    },
    removeLastTargetZonePoint: (state) => {
      state.targetZonePoints.pop();
    },
    removeLastNegativeTargetZonePoint: (state) => {
      state.negativeTargetZonePoints.pop();
    },
    saveTargetZone: (state, { payload: zone }: PayloadAction<TargetZone>) => {
      state.targetZonePoints = [];
      //state.zoneRadius = 0;
      state.negativeTargetZonePoints = [];
      state.negativeZoneRadius = 0;
      state.savedTargetZones.push(zone);
    },
    savePreviewArea: (state, { payload: area }: PayloadAction<number>) => {
      state.savedPreviewArea = area;
    },

    savePreviewAudience: (
      state,
      { payload: audience }: PayloadAction<number>
    ) => {
      state.savedPreviewAudience = audience;
    },

    removeTargetZone: (
      state,
      { payload: zoneToDelete }: PayloadAction<TargetZone>
    ) => {
      // This uses the points of a zone as a key, and removes any zone that has the exact matching points
      return {
        ...state,
        savedTargetZones: state.savedTargetZones.filter((zone) => {
          if (zone.points && zoneToDelete.points) {
            return !polygonsHaveMatchingPoints(
              zone.points,
              zoneToDelete.points
            );
          } else if (zone.center && zoneToDelete.center) {
            return !polygonsHaveMatchingPoints(
              [zone.center],
              [zoneToDelete.center]
            );
          } else {
            return true;
          }
        }),
      };
    },
    setSelectedChannels: (
      state,
      { payload: selectedChannels }: PayloadAction<ChannelType[]>
    ) => {
      state.selectedCustomPackage = null;
      state.SelectedChannels = selectedChannels;
      state.avgChannelCostPercent = Math.floor(100 / selectedChannels.length);
    },
    setSelectedCustomPackage: (
      state,
      { payload: customPackage }: PayloadAction<CustomPackage | null>
    ) => {
      state.selectedCustomPackage = customPackage;
      state.SelectedChannels = [];
    },
    setNewCampaignChannels: (
      state,
      { payload: channels }: PayloadAction<NewCampaignChannel[]>
    ) => {
      state.newCampaignChannels = channels;
    },
    updateNewCampaignChannel: (
      state,
      { payload: updatedChannel }: PayloadAction<NewCampaignChannel>
    ) => {
      state.newCampaignChannels = state.newCampaignChannels.map((channel) => {
        // there should never be more than one of each type per campaign
        if (channel.channelType.id === updatedChannel.channelType.id) {
          return updatedChannel;
        } else {
          return channel;
        }
      });
    },
    setNewCampaignCustomPackage: (
      state,
      { payload: customPackage }: PayloadAction<NewCampaignCustomPackage | null>
    ) => {
      state.newCampaignCustomPackage = customPackage;
    },
    setTemplateTitle: (
      state,
      { payload: templateTitle }: PayloadAction<string>
    ) => {
      state.templateTitle = templateTitle;
    },
    setTemplateChannel: (
      state,
      { payload: templateChannel }: PayloadAction<ChannelType | null>
    ) => {
      state.templateChannel = templateChannel;
    },
    // toggleAlwaysOn: (state) => {
    //     if (state.fixedBudgetMode) {
    //         state.alwaysOn = false;
    //         return;
    //     }
    //   state.alwaysOn = !state.alwaysOn;
    // },
    toggleAlwaysOn: (state) => {
        state.alwaysOn = true;
    },
    setSetSpend: (
      state,
      { payload: setSpend }: PayloadAction<number | null>
    ) => {
      state.setSpend = setSpend
    },
    setIsSetSpend: (
      state,
      { payload: isSetSpend }: PayloadAction<boolean>
    ) => {
      state.isSetSpend = isSetSpend
    },
    setAdjustedSpendPercent: (
      state,
      { payload: percent }: PayloadAction<number>
    ) => {
      state.adjustedSpendPercent = percent;
    },
    setPaymentMethod: (
      state,
      { payload: paymentMethod }: PayloadAction<string>
    ) => {
      state.paymentMethod = paymentMethod;
    },
    toggleView: (state) => {
      state.view = !state.view;
    },
    toggleDisplayCrosswalk: (state) => {
      state.displayRedemptionsOverlay = false;
      state.displayCrosswalkOverlay = !state.displayCrosswalkOverlay;
    },
    toggleDisplayRedemptions: (state) => {
      state.displayCrosswalkOverlay = false;
      state.displayRedemptionsOverlay = !state.displayRedemptionsOverlay;
    },
    toggleIsCustomTargetArea: (state) => {
      state.targetZonePoints = [];
      state.zoneRadius = 0;
      state.isCustomTargetArea = !state.isCustomTargetArea;
    },
    toggleIsNegativeCustomTargetArea: (state) => {
      state.negativeTargetZonePoints = [];
      state.negativeZoneRadius = 0;
      state.isNegativeCustomTargetArea = !state.isNegativeCustomTargetArea;
    },
    setIsZoneSelected: (
      state,
      { payload: isZoneSelected }: PayloadAction<boolean>
    ) => {
      state.isZoneSelected = isZoneSelected;
    },
    setAlertAgreedToTerms: (
      state,
      { payload: alertAgreedToTerms }: PayloadAction<boolean>
    ) => {
      state.alertAgreedToTerms = alertAgreedToTerms;
    },
    setAgreedToTerms: (
      state,
      { payload: agreedToTerms }: PayloadAction<boolean>
    ) => {
      state.agreedToTerms = agreedToTerms;
    },
    setSelectedTemplates: (
      state,
      { payload: templates }: PayloadAction<CreativeTemplate[]>
    ) => {
      state.selectedTemplates = templates;
    },
    setPreSelectedTemplates: (
      state,
      { payload: templates }: PayloadAction<CreativeTemplate[]>
    ) => {
      state.preSelectedTemplates = templates;
    },
    setSingleLocation: (
      state,
      { payload: singleLocation }: PayloadAction<boolean>
    ) => {
      state.singleLocation = singleLocation;
    },
    setHandleCreateAudience: (
      state,
      { payload: handleCreateAudience }: PayloadAction<() => void>
    ) => {
      state.handleCreateAudience = handleCreateAudience;
    },
    setTargetZoneChanged: (
      state,
      { payload: targetZoneChanged }: PayloadAction<boolean>
    ) => {
      state.targetZoneChanged = targetZoneChanged;
    },
    setSelectableLocations: (
      state,
      { payload: selectableLocations }: PayloadAction<string>
    ) => {
      state.selectableLocations = selectableLocations;
    },
    setAudiences: (state, {payload: audiences}: PayloadAction<Audience[]>) => {
      state.audiences = audiences;
    },
    setPrimaryZips: (state, {payload: primaryZips}: PayloadAction<string[]>) => {
      state.primaryZips = primaryZips;
    },
    setSecondaryZips: (state, {payload: secondaryZips}: PayloadAction<string[]>) => {
      state.secondaryZips = secondaryZips;
    },
    setZipModeEnabled: (
      state,
      { payload: zipModeEnabled }: PayloadAction<boolean>
    ) => {
      state.zipModeEnabled = zipModeEnabled;
    },
    setSelectedMonth: (state, {payload: month}: PayloadAction<string>) => {
      state.selectedMonth = month;
    },
    setAudienceSegment: (state, {payload: audienceSegment}: PayloadAction<string[]>) => {
      state.audienceSegment = audienceSegment;
    },
    setNewBudget: (
      state,
      { payload: newBudget }: PayloadAction<number>
    ) => {
      state.newBudget = newBudget
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAudiences.fulfilled, (state, action) => {
      state.audiences = action.payload;
    });

    builder.addCase(fetchMonthlyBudget.fulfilled, (state, action) => {
      console.log('Obtained the Monthly Budgets for this location');
      console.log(action.payload);
      //state.fixedBudgetMode = action.payload.budgetMode === 'fixed';
      state.monthlyBudget = action.payload.budgets;
    });
  }
});

export const selectSelectedLocation = (state: RootState): Location | null =>
  state.campaignForm.selectedLocation;

export const selectDetailsLocation = (state: RootState): Location | null =>
  state.campaignForm.detailsLocation;

export const selectZoneRadius = (state: RootState): number =>
  state.campaignForm.zoneRadius;

export const selectTargetZonePoints = (state: RootState): LatLonPair[] =>
  state.campaignForm.targetZonePoints;

export const selectNegativeZoneRadius = (state: RootState): number =>
  state.campaignForm.negativeZoneRadius;

export const selectNegativeTargetZonePoints = (
  state: RootState
): LatLonPair[] => state.campaignForm.negativeTargetZonePoints;

export const selectIsNegativeZone = (state: RootState): boolean =>
  state.campaignForm.isNegativeZone;

export const selectNewCampaignChannels = (
  state: RootState
): NewCampaignChannel[] => state.campaignForm.newCampaignChannels;

export const selectNewCampaignCustomPackage = (
  state: RootState
): NewCampaignCustomPackage | null =>
  state.campaignForm.newCampaignCustomPackage;

export const selectSelectedChannels = (state: RootState): ChannelType[] =>
  state.campaignForm.SelectedChannels;

export const selectSelectedCustomPackage = (
  state: RootState
): CustomPackage | null => state.campaignForm.selectedCustomPackage;

export const selectTemplateTitle = (state: RootState): string =>
  state.campaignForm.templateTitle;
 
export const selectTemplateChannel = (state: RootState): (ChannelType | null) =>
  state.campaignForm.templateChannel;

export const selectTotalAudience = (state: RootState): number =>
  state.campaignForm.savedTargetZones.reduce(
    (total, current) => total + current.audience,
    0
  );

export const selectTotalArea = (state: RootState): number =>
  state.campaignForm.savedTargetZones.reduce(
    (total, current) => total + current.area,
    0
  );

export const selectSavedTargetZones = (state: RootState): TargetZone[] =>
  state.campaignForm.savedTargetZones;

export const selectPreviewArea = (state: RootState): number =>
  state.campaignForm.savedPreviewArea;

export const selectPreviewAudience = (state: RootState): number =>
  state.campaignForm.savedPreviewAudience;

export const selectAlwaysOn = (state: RootState): boolean =>
  state.campaignForm.alwaysOn;

export const selectAdjustedSpendPercent = (state: RootState): number =>
  state.campaignForm.adjustedSpendPercent;

export const selectSetSpend = (state: RootState): number | null =>
  state.campaignForm.setSpend;

export const selectIsSetSpend = (state: RootState): boolean =>
  state.campaignForm.isSetSpend;

export const selectHasFranchiseSetSpend = (state: RootState): boolean => {
  const {
    packages: { packages },
    channels: { channels },
  } = state;

  if (packages.find(custPackage => custPackage.setSpend)) return true;
  if (channels.find(channel => channel.setSpend)) return true;

  return false;
}

export const selectPaymentMethod = (state: RootState): string =>
  state.campaignForm.paymentMethod;

export const selectView = (state: RootState): boolean =>
  state.campaignForm.view;

export const selectIsCustomTargetArea = (state: RootState): boolean =>
  state.campaignForm.isCustomTargetArea;

export const selectIsNegativeCustomTargetArea = (state: RootState): boolean =>
  state.campaignForm.isNegativeCustomTargetArea;

export const selectDisplayCrosswalkOverlay = (state: RootState): boolean =>
  state.campaignForm.displayCrosswalkOverlay;

export const selectDisplayRedemptionsOverlay = (state: RootState): boolean =>
  state.campaignForm.displayRedemptionsOverlay;

export const selectCurrentEstimatedCost = (state: RootState): number => {
  
  const {
    SelectedChannels,
    savedTargetZones,
    selectedCustomPackage,
    newCampaignChannels,
    alwaysOn,
  } = state.campaignForm;
  const totalAudience = savedTargetZones.reduce(
    (total, current) => total + current.audience,
    0
  );


  let estimatedCost = 0;
  if (newCampaignChannels.length > 0 && newCampaignChannels.length == SelectedChannels.length) {
    // calculate cost based on duration selected
    newCampaignChannels.forEach((channel) => {
      
      if( channel.channelType.setSpend != null ){
        estimatedCost += channel.channelType.setSpend;
      }else{
        const totalHours = getHoursBetween2Dates(
          channel.startDate,
          channel.endDate ??
          addDaysToDate(
            channel.startDate,
            channel.channelType.durationHours / HOURS_IN_DAY
          )
        );
  
        const costPerTargetPerHour =
          channel.channelType.investmentInDollarsPerTarget /
          channel.channelType.durationHours;
  
        estimatedCost += costPerTargetPerHour * totalAudience * totalHours;
      }
      
    });
  } else {
    SelectedChannels.forEach((channel) => {
      if( channel.setSpend != null ){
        estimatedCost += channel.setSpend;
      }else{
        estimatedCost += channel.investmentInDollarsPerTarget * totalAudience;
      }
      
    });

    if (selectedCustomPackage) {
      estimatedCost += selectedCustomPackage.setSpend || selectedCustomPackage.costPerTarget * totalAudience;
    }
  }
  return estimatedCost;
};

export const selectWeeklySpendEstimate = (state: RootState): number => {
  const estimatedCost = selectAlwaysOnEstimatedBudget(state);

  return (estimatedCost * state.campaignForm.adjustedSpendPercent) / 100;
};

export const selectAlwaysOnEstimatedBudget = (state: RootState): number => {
  const { SelectedChannels, savedTargetZones, selectedCustomPackage } = state.campaignForm;
  const totalAudience = savedTargetZones.reduce(
    (total, current) => total + current.audience,
    0
  );

  let estimatedCost = 0;

  const channels = SelectedChannels;

  if (selectedCustomPackage) {
    estimatedCost +=
        selectedCustomPackage.costPerTarget *
        totalAudience *
        STANDARD_BILLING_PERIOD_WEEKS;
  }

  channels.forEach((channel) => {
    const costPerTargetPerHour =
      channel.investmentInDollarsPerTarget / channel.durationHours;

    estimatedCost +=
      costPerTargetPerHour *
      totalAudience *
      HOURS_IN_A_WEEK *
      STANDARD_BILLING_PERIOD_WEEKS;
  });

  return Number(estimatedCost.toFixed(2));
};

export const selectIsZoneSelected = (state: RootState): boolean =>
  state.campaignForm.isZoneSelected;

export const selectAgreedToTerms = (state: RootState): boolean =>
  state.campaignForm.agreedToTerms;

export const selectAlertAgreedToTerms = (state: RootState): boolean =>
  state.campaignForm.alertAgreedToTerms;
  
export const selectSelectedTemplates = (state: RootState): CreativeTemplate[] =>
  state.campaignForm.selectedTemplates;

export const selectPreSelectedTemplates = (
  state: RootState
): CreativeTemplate[] => state.campaignForm.preSelectedTemplates;

export const selectSingleLocation = (state: RootState): boolean => state.campaignForm.singleLocation;

export const selectHandleCreateAudience = (state: RootState): (() => void) => state.campaignForm.handleCreateAudience;

export const selectTargetZoneChanged = (state: RootState): boolean => state.campaignForm.targetZoneChanged;

export const selectSelectableLocations = (state: RootState): string => state.campaignForm.selectableLocations;

export const selectAudiences = (state: RootState): Audience[] => state.campaignForm.audiences;

export const selectZipModeEnabled = (state: RootState): boolean => {

  //If the selected location has no zip codes at all, this will always evaluate to false.
  //Otherwise fetchs the stored value.
  if(
    (state.campaignForm.selectedLocation && state.campaignForm.selectedLocation.primaryZips) ||
    (state.campaignForm.selectedLocation && state.campaignForm.selectedLocation.secondaryZips)
  ){
    return state.campaignForm.zipModeEnabled;
  }else{
    return false;
  }

}

export const selectPrimaryZips = (state: RootState): string[] => state.campaignForm.primaryZips;

export const selectSecondaryZips = (state: RootState): string[] => state.campaignForm.secondaryZips;

/** The Zips available as a list for the selected location of the current campaign */
export const selectSelectedLocationPrimaryZips = (state: RootState): string[] => {
  if( state.campaignForm.selectedLocation && state.campaignForm.selectedLocation.primaryZips ){
    return state.campaignForm.selectedLocation.primaryZips.split(',');
  }else{
    return [];
  }
};
/** The Zips available as a list for the selected location of the current campaign */
export const selectSelectedLocationSecondaryZips = (state: RootState): string[] => {
  if( state.campaignForm.selectedLocation && state.campaignForm.selectedLocation.secondaryZips ){
    return state.campaignForm.selectedLocation.secondaryZips.split(',');
  }else{
    return [];
  }
};

export const selectIsBudgetModeFixed = (state: RootState): boolean => {
  if( state.accounts.userAccount ){
    return state.accounts.userAccount.billingMode === "approval";
  }else{
    return false;
  }
};

export const selectAvgChannelCostPercent = (state: RootState): number => state.campaignForm.avgChannelCostPercent;

export const selectMonthlyBudget = (state: RootState): IMonthlyBudgetDetail[]|null => state.campaignForm.monthlyBudget;

export const selectedMonth = (state: RootState): string => state.campaignForm.selectedMonth;

export const selectAudienceSegment = (state: RootState): string[] => state.campaignForm.audienceSegment;

export const selectNewBudget = (state: RootState): number => state.campaignForm.newBudget;
export const selectDefaultRadiusInMiles = () =>  20; // default radius applied to map and input field

export const {
  setSelectedLocation,
  setDetailsLocation,
  addTargetZonePoint,
  setTargetZonePoints,
  removeLastTargetZonePoint,
  setSelectedChannels,
  setSelectedCustomPackage,
  setTemplateTitle,
  setTemplateChannel,
  toggleAlwaysOn,
  setSetSpend,
  setIsSetSpend,
  setAdjustedSpendPercent,
  setNewCampaignChannels,
  setNewCampaignCustomPackage,
  updateNewCampaignChannel,
  setPaymentMethod,
  resetNewCampaignForm,
  toggleView,
  toggleDisplayCrosswalk,
  toggleDisplayRedemptions,
  saveTargetZone,
  savePreviewArea,
  savePreviewAudience,
  removeTargetZone,
  toggleIsCustomTargetArea,
  setZoneRadius,
  setIsZoneSelected,
  setAgreedToTerms,
  setAlertAgreedToTerms,
  setNegativeTargetZonePoints,
  setNegativeZoneRadius,
  setIsNegativeZone,
  addNegativeTargetZonePoint,
  removeLastNegativeTargetZonePoint,
  toggleIsNegativeCustomTargetArea,
  setSelectedTemplates,
  setPreSelectedTemplates,
  setSingleLocation,
  setHandleCreateAudience,
  setTargetZoneChanged,
  setSelectableLocations,
  setAudiences,
  setPrimaryZips,
  setSecondaryZips,
  setZipModeEnabled,
  setSelectedMonth,
  setAudienceSegment,
  setNewBudget
} = campaignSlice.actions;

export default campaignSlice.reducer;
