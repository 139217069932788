import { rejects } from "assert";
import { resolve } from "path";
import { CountryEnum, BudgetEnum, Location } from "../models/Location";
import RestService from "./restService";

export interface INewLocation {
  name: string;
  owner: string;
  address1: string;
  address2: string | null;
  // proximity: string;
  city: string;
  state: string;
  postalCode: string;
  country: CountryEnum | null;
  // latitude: number;
  // longitude: number;
  externalId: string;
  budgetMode: BudgetEnum | null;
  market: string | null;
  mapEmbed: string | null;
  nickname: string | null;
  vigI: string | null;
  vigS: string | null;
}

export interface IUpdateLocation {
  id: string;
  name: string;
  owner?: string | null;
  // owner: string;
  address1: string;
  address2: string | null;
  // proximity: string;
  city: string;
  state: string;
  postalCode: string;
  country: CountryEnum | null;
  // latitude: number;
  // longitude: number;
  externalId: string;
  budgetMode: BudgetEnum | null;
  market: string | null;
  mapEmbed: string | null;
  nickname: string | null;
  vigI: string | null;
  vigS: string | null;
  // isRegion: boolean | null;
  // gadsCampaignId: string;
  // fadsCampaignId: string;
  // fadsAdSetId: string;
  // gaLpKey: string;
  // primaryZips: string;
  // secondaryZips: string;
  // primaryCoords: string;
  // secondaryCoords: string;
}

//The expected data in the locations file. All attributes are required.
//Used when importing rows from csv or xlsx
export interface LocationRowSchema {
  name: string,
  appId: string,
  market: string,
  franchise: string,
  owner: string,
  proximity: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  postalCode: string,
  country: string,
  latitude: string,
  longitude: string,
  phone: string,
  gadsCampaignId: string,
  fadsAdSetId: string,
  fadsCampaignId: string,
  gaLpKey: string,
  isRegion: boolean,
  scope: string | null,
  scopeLabel: string | null,
  primaryZips: string,
  secondaryZips: string,
  monthlyCpl: number[],
  secondUrl: string | null,
  monthlyBudget: number[],
  budgetMode: string,
  importType: string
  //__rowNum__: number,
}

export interface LocationGadsKeyword {
  key: string;
  word: string;
  ctr: number;
  cost: number;
  impressions: number;
}

export interface LocationGadsDate {
  date: string,
  clicks: number,
  impressions: number,
  ctr: number,
  cpc: number,
  cost: number,
}
export interface LocationCustomDate {
  date: string,
  clicks: number,
  impressions: number,
  ctr: number,
  cpc: number,
  spend: number,
}
export interface LocationGadsSchema {
  dates: LocationGadsDate[],
  error: string | undefined,
  lastStartDate: string | undefined,
  lastEndDate: string | undefined,
  name: string,
}


export interface LocationGadsKeyWords {
  keyWords: LocationGadsKeyword[],
  error: string | undefined,
  lastStartDate: string | undefined,
  lastEndDate: string | undefined,
  name: string,
}

export interface LocationFadsSchema {
  dates: LocationGadsDate[],
  error: string | undefined,
  lastStartDate: string | undefined,
  lastEndDate: string | undefined,
  name: string,
}
export interface LocationCustomChannelSingleSchema {
  dates: LocationCustomDate[],
  channelName: string,
  name: string,
}
export interface LocationCustomChannelSchema {
  customChannels: LocationCustomChannelSingleSchema[],
  error: string | undefined,
  lastStartDate: string | undefined,
  lastEndDate: string | undefined,
  name: string,
}

export default class LocationService {
  constructor(protected readonly api: RestService) { }

  async getGoogleAdsReportSingle(id: string, startDate?: string, endDate?: string): Promise<LocationGadsSchema> {
    const query: string[] = [];
    if (startDate) {
      query.push(`startDate=${startDate}`);
    }
    if (endDate) {
      query.push(`endDate=${endDate}`);
    }
    const res = await this.api.get<any>(`/locations/reports/google/${id}/${query.length ? '?' + query.join('&') : ''}`);
    if (res.error) {
      return {
        dates: [],
        error: res.error,
        lastStartDate: startDate,
        lastEndDate: endDate,
        name: res.name,
      };
    }
    return {
      dates: res.results,
      error: undefined,
      lastStartDate: startDate,
      lastEndDate: endDate,
      name: res.name,
    };
  }

  async getGoogleAdsKeyWords(id: string, startDate?: string, endDate?: string): Promise<LocationGadsKeyWords> {
    const query: string[] = [];
    if (startDate) {
      query.push(`startDate=${startDate}`);
    }
    if (endDate) {
      query.push(`endDate=${endDate}`);
    }
    const res = await this.api.get<any>(`/locations/reports/google/keywords/${id}/${query.length ? '?' + query.join('&') : ''}`);
    if (res.error) {
      return {
        keyWords: [],
        error: res.error,
        lastStartDate: startDate,
        lastEndDate: endDate,
        name: res.name,
      };
    }
    return {
      keyWords: res.results,
      error: undefined,
      lastStartDate: startDate,
      lastEndDate: endDate,
      name: res.name,
    };
  }

  

  async getFacebookAdsReportSingle(id: string, startDate?: string, endDate?: string): Promise<LocationFadsSchema> {
    const query: string[] = [];
    if (startDate) {
      query.push(`startDate=${startDate}`);
    }
    if (endDate) {
      query.push(`endDate=${endDate}`);
    }
    const res = await this.api.get<any>(`/locations/reports/facebook/${id}/${query.length ? '?' + query.join('&') : ''}`);
    if (res.error) {
      return {
        dates: [],
        error: res.error,
        lastStartDate: startDate,
        lastEndDate: endDate,
        name: res.name,
      };
    }
    return {
      dates: res.results,
      error: undefined,
      lastStartDate: startDate,
      lastEndDate: endDate,
      name: res.name,
    };
  }

  async getCustomChannelReportSingle(id: string, startDate?: string, endDate?: string): Promise<LocationCustomChannelSchema> {
    const query: string[] = [];
    if (startDate) {
      query.push(`startDate=${startDate}`);
    }
    if (endDate) {
      query.push(`endDate=${endDate}`);
    }
    const res = await this.api.get<any>(`/locations/reports/custom-channel/${id}/${query.length ? '?' + query.join('&') : ''}`);
    if (res.error) {
      return {
        customChannels: [],
        error: res.error,
        lastStartDate: startDate,
        lastEndDate: endDate,
        name: res.name,
      };
    }
    return {
      customChannels: res.results,
      error: undefined,
      lastStartDate: startDate,
      lastEndDate: endDate,
      name: res.name,
    };
  }

  async getUserLocations(): Promise<Location[]> {
    const apiResponse = await this.api.get<Location[]>("/locations");

    return apiResponse;
  }

  async getFranchiseLocations(franchiseId: string): Promise<Location[]> {
    const apiResponse = await this.api.get<Location[]>(
      `/locations/franchise/${franchiseId}`
    );

    return apiResponse;
  }

  async createLocation(createLocation: INewLocation): Promise<Location> {
    return await this.api.post<Location>("/locations", createLocation);
  }

  async updateLocation(updateLocation: IUpdateLocation): Promise<Location> {
    return await this.api.put<Location>("/locations", updateLocation);
  }

  async importLocations(locationSchemaArray: LocationRowSchema[]): Promise<Location[]> {
    console.log(locationSchemaArray);
    const apiResponse = await this.api.post<Location[]>("/locations/import", locationSchemaArray);

    return apiResponse;
  }

  async getFranchiseeLocationsByMarketName(
    marketName: string | null,
  ): Promise<Location[]> {
    if (!marketName) return [];
    const locations = await this.api.get<Location[]>(
      `/locations/marketName/${marketName}/location`
    );
    return locations;
  }
}
