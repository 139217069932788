import { AdCampaign } from "../models/AdCampaign";
import { CampaignChannel, NewCampaignChannel } from "../models/CampaignChannel";
import {
  CampaignCustomPackage,
  NewCampaignCustomPackage,
} from "../models/CampaignPackage";
import { TargetZone } from "../models/TargetZone";
import RestService from "./restService";

export interface PlatformOrderDetail{
  id: string,
  locationName: string,
  status: string,
  link: string,
  date: string,
  channelList: string,
}

export interface MonthlyPlatformOrders{
  month: string;
  orders: PlatformOrderDetail[];
}

export default class AdCampaignService {
  constructor(protected readonly api: RestService) { }

  // Will we need to get these by month eventually to limit response size?
  async getAdCampaigns(): Promise<AdCampaign[]> {
    const responseCampaigns = await this.api.get<AdCampaign[]>("/adCampaigns");
    // Dates come from the server as strings so we cast them as dates here
    return responseCampaigns.map((campaign) => {
      return {
        ...campaign,
        startDate: new Date(campaign.startDate),
        endDate: campaign.endDate ? new Date(campaign.endDate) : null,
        campaignChannels: campaign.campaignChannels.map(
          (channel): CampaignChannel => {
            return {
              ...channel,
              startDate: new Date(channel.startDate),
              endDate: channel.endDate ? new Date(channel.endDate) : null,
            };
          }
        ),
        campaignPackages: campaign.campaignPackages.map(
          (cPackage): CampaignCustomPackage => {
            return {
              ...cPackage,
              startDate: new Date(cPackage.startDate),
              endDate: new Date(cPackage.endDate),
            };
          }
        ),
      };
    });
  }

  async getMonthlyPlatformOrders():Promise<MonthlyPlatformOrders[]>{
    return await this.api.get<MonthlyPlatformOrders[]>("/adCampaigns/monthly-platform-orders");
  }

  async createAdCampaign(
    accountId: string,
    newCampaignChannels: NewCampaignChannel[],
    newPackages: NewCampaignCustomPackage[],
    audienceSize: number,
    areaInMiles: number,
    costInDollars: number,
    locationIds: string[],
    cardId: string,
    zones: TargetZone[],
    adjustedSpendPercent: number,
    alwaysOn: boolean,
    zips: string[],
    month: string | null,
    segments: string[],
    isNewBudget: boolean,
    newBudget: number,
    billingMode: string,
  ): Promise<AdCampaign> {
    const start = new Date(
      Math.min(
        ...newCampaignChannels.map((channel) =>
          new Date(channel.startDate).getTime()
        ),
        ...newPackages.map((customPackage) =>
          new Date(customPackage.startDate).getTime()
        )
      )
    );

    let end: Date | undefined = undefined;
    const endDates = newCampaignChannels
      .filter((channel) => channel.endDate !== undefined)
      .map((channel) => channel.endDate as Date);
    endDates.push(
      ...newPackages.map((customPackage): Date => customPackage.endDate)
    );

    if (endDates.length > 0) {
      end = new Date(
        Math.max(...endDates.map((endDate) => new Date(endDate).getTime()))
      );
    }

    console.log(billingMode);

    const responseCampaign = await this.api.post<AdCampaign>("/adCampaigns", {
      title:
        newPackages.length > 0 ? newPackages[0].customPackage.title : undefined,
      //   type: string, // Not sure what all this can be but it's in the designs
      channels: newCampaignChannels.map((newChannel) => {
        return {
          channelTypeId: newChannel.channelType.id,
          startDate: newChannel.startDate,
          endDate: newChannel.endDate,
          creativeId: newChannel.creativeId,
        };
      }),
      packages: newPackages.map((newPackage) => {
        return {
          startDate: newPackage.startDate,
          endDate: newPackage.endDate,
          customPackageId: newPackage.customPackage.id,
        };
      }),
      locationIds,
      startDate: start,
      endDate: alwaysOn ? null : end,
      alwaysOn,
      adjustedSpendPercent,
      audienceSize,
      areaInMiles,
      costInDollars,
      cardId,
      zones,
      zips,
      month,
      segments,
      isNewBudget,
      newBudget,
      billingMode
    });
    return {
      ...responseCampaign,
      startDate: new Date(responseCampaign.startDate),
      endDate: responseCampaign.endDate
        ? new Date(responseCampaign.endDate)
        : null,
      costInDollars: Number(responseCampaign.costInDollars),
      campaignChannels: responseCampaign.campaignChannels.map(
        (channel): CampaignChannel => {
          return {
            ...channel,
            startDate: new Date(channel.startDate),
            endDate: channel.endDate ? new Date(channel.endDate) : null,
          };
        }
      ),
      campaignPackages: responseCampaign.campaignPackages.map(
        (cPackage): CampaignCustomPackage => {
          return {
            ...cPackage,
            startDate: new Date(cPackage.startDate),
            endDate: new Date(cPackage.endDate),
          };
        }
      ),
    };
  }
}

export interface GetAdCampaignsResponse {
  adCampaigns: AdCampaign[];
}
