import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Location } from "../../models/Location";
import { EnumBillingMode, ICreateUser, IUpdateUser, IUser, IClientSecret, IUserBillingPreferences } from "../../models/User";
import services from "../../services";
import { RootState } from "../index";

export const updateUser = createAsyncThunk(
  "users/update",
  async (user: IUpdateUser) => {
    return services.userService.updateUser(user);
  }
);

export const UpdateUserBillingPreferences = createAsyncThunk(
  "users/update-billing-preferences",
  async (preferences: IUserBillingPreferences) => {
    return services.userService.updateUserBillingPreferences(preferences);
  }
);

export const fetchUser = createAsyncThunk("users/fetch", async () => {
  return services.userService.getUser();
});

export const createUser = createAsyncThunk(
  "users/create",
  async (createUser: ICreateUser) => {
    return services.userService.createUser(createUser);
  }
);

export const addUserPayment = createAsyncThunk(
  "users/payment/add",
  async (cardToken: string) => {
    return services.userService.addPaymentToUser(cardToken);
  }
);

export const addUserACHPayment = createAsyncThunk(
  "users/payment/addACH",
  async (setUpIntentId: string) => {
    return services.userService.addACHPaymentToUser(setUpIntentId);
  }
);

export const createSetUpIntent = createAsyncThunk(
  "users/setUpIntent/",
  async () => {
    return services.userService.createSetUpIntent();
  }
);

export const removeUserPayment = createAsyncThunk(
  "users/payment/remove",
  async (cardToken: string) => {
    return services.userService.removePaymentFromUser(cardToken);
  }
);

export const deleteAlert = createAsyncThunk(
  "users/alerts/delete",
  async (eventId: string) => {
    return services.userService.deleteAlert(eventId);
  }
);

export const getFranchisees = createAsyncThunk("users", async () => {
  return await services.userService.getFranchisees();
});

// export const getFranchiseeLocations = createAsyncThunk(
//   "user/locations",
//   async (franchiseeId: string) => {
//     return await services.userService.getFranchiseeLocations(franchiseeId);
//   }
// );

export const getUnregisteredFranchisees = createAsyncThunk(
  "users/unregisteredFranchisees",
  async () => {
    return await services.userService.getUnregisteredFranchisees();
  }
);

export const getFranchiseeLocationsByEmail = createAsyncThunk(
  "user/email/locations",
  async (franchiseeEmail: string | null) => {
    return await services.userService.getFranchiseeLocationsByEmail(
      franchiseeEmail
    );
  }
);

export const accountSlice = createSlice({
  name: "accounts",
  initialState: {
    clientSecret: null as IClientSecret | null,
    userAccount: null as IUser | null,
    franchisees: [] as IUser[],
    unregisteredFranchisees: [] as string[],
    franchiseeLocations: [] as Location[],
  },
  reducers: {
    setUser: (state, { payload: user }: PayloadAction<IUser | null>) => {
      state.userAccount = user;
    },
    setFranchisees: (state, { payload: franchisees }: PayloadAction<IUser[]>) => {
      state.franchisees = franchisees;
    },
    setClientSecret: (state, { payload: clientSecret }: PayloadAction<IClientSecret>) => {
      state.clientSecret = clientSecret;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.userAccount = action.payload;
    });
    builder.addCase(UpdateUserBillingPreferences.fulfilled, (state, action) => {
      state.userAccount = action.payload;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.userAccount = action.payload;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.userAccount = action.payload;
    });
    builder.addCase(addUserPayment.fulfilled, (state, action) => {
      state.userAccount = action.payload;
    });
    builder.addCase(addUserACHPayment.fulfilled, (state, action) => {
      state.userAccount = action.payload;
    });
    builder.addCase(createSetUpIntent.fulfilled, (state, action) => {
      state.clientSecret = action.payload;
    });
    builder.addCase(removeUserPayment.fulfilled, (state, action) => {
      state.userAccount = action.payload;
    });
    builder.addCase(deleteAlert.fulfilled, (state, action) => {
      state.userAccount!.alerts = state.userAccount!.alerts?.filter((alert) => {
        return alert.id !== action.payload.toString();
      });
    });
    builder.addCase(getFranchisees.fulfilled, (state, action) => {
      state.franchisees = action.payload;
    });
    builder.addCase(getUnregisteredFranchisees.fulfilled, (state, action) => {
      state.unregisteredFranchisees = action.payload;
    });
    // builder.addCase(getFranchiseeLocations.fulfilled, (state, action) => {
    //   state.franchiseeLocations = action.payload;
    // });
    builder.addCase(
      getFranchiseeLocationsByEmail.fulfilled,
      (state, action) => {
        state.franchiseeLocations = action.payload;
      }
    );
      
  },
});

export const selectUserAccount = (state: RootState): IUser | null =>
  state.accounts.userAccount;

export const selectFranchisees = (state: RootState): IUser[] =>
  state.accounts.franchisees;

export const selectUnregisteredFranchisees = (state: RootState): string[] =>
  state.accounts.unregisteredFranchisees;

export const selectFranchiseeLocations = (state: RootState): Location[] =>
  state.accounts.franchiseeLocations;

export const selectClientSecret = (state: RootState): IClientSecret | null =>
  state.accounts.clientSecret;

export const { setUser, setFranchisees } = accountSlice.actions;

export default accountSlice.reducer;
