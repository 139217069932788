import { Button, Form, Input, Checkbox} from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { message, Upload } from 'antd';
import { assetImage } from "../../../utility/whiteLabelManager";
import AWS from "aws-sdk";
import { selectOnboardingFranchise, updateOnboardingFranchise } from "store/OnboardingFranchise/OnboardingFranchiseStore";

const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

export const OnboardingBrand = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    const [faviconImageUrl, setFaviconImageUrl] = useState<string>();
    const [secondaryImageUrl, setSecondaryImageUrl] = useState<string>();
    const onboardingFranchise = useSelector( selectOnboardingFranchise );

    useEffect( () => {
        if(onboardingFranchise?.logoPath )
            setImageUrl(onboardingFranchise?.logoPath)

        if(onboardingFranchise?.faviconPath )
            setFaviconImageUrl(onboardingFranchise?.faviconPath)

        if(onboardingFranchise?.logoNegativePath )
            setSecondaryImageUrl(onboardingFranchise?.logoNegativePath  )
        
    }, [onboardingFranchise] )
    
    const uploadToS3 = async (options : any) => {
        const { onSuccess, onError, file } = options;
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY!,
            region: process.env.REACT_APP_AWS_S3_REGION!,
            secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET!
        });
        const S3 = new AWS.S3();
        const fileName = Date.now().toString() + file.name;
        const s3Params = {
            Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME!,
            Key: fileName,
            Body: file
        }
        try {
            S3.putObject(s3Params).send(function(err,data){
                if(err){
                    onError();
                }else{
                    setImageUrl(fileName);
                    onSuccess(data);
                }
            })
        } catch (err) {
            const error = new Error("Some error");
            onError({ err });
        }
    };
    
    const uploadSecondaryToS3 = async (options : any) => {
        const { onSuccess, onError, file } = options;
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY!,
            region: process.env.REACT_APP_AWS_S3_REGION!,
            secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET!
        });
        const S3 = new AWS.S3();
        const fileName = Date.now().toString() + file.name;
        const s3Params = {
            Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME!,
            Key: fileName,
            Body: file
        }
        try {
            S3.putObject(s3Params).send(function(err,data){
                if(err){
                    onError();
                }else{
                    setSecondaryImageUrl(fileName);
                    onSuccess(data);
                }
            })
        } catch (err) {
            const error = new Error("Some error");
            onError({ err });
        }
    };
    
    const uploadFaviconToS3 = async (options : any) => {
        const { onSuccess, onError, file } = options;
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY!,
            region: process.env.REACT_APP_AWS_S3_REGION!,
            secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET!
        });
        const S3 = new AWS.S3();
        const fileName = Date.now().toString() + file.name;
        const s3Params = {
            Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME!,
            Key: fileName,
            Body: file
        }
        try {
            S3.putObject(s3Params).send(function(err,data){
                if(err){
                    onError();
                }else{
                    setFaviconImageUrl(fileName);
                    onSuccess(data);
                }
            })
        } catch (err) {
            const error = new Error("Some error");
            onError({ err });
        }
    };
    
    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onFinish = (values: any) => {
        const updatedFranchise = {
            logoPath: imageUrl,
            logoNegativePath: secondaryImageUrl,
            faviconPath: faviconImageUrl
        };

        dispatch(updateOnboardingFranchise({
            ...onboardingFranchise,
            ...updatedFranchise
        }));
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return ( <>
        <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="container-form w-5/6"
            layout="vertical"
            
        >
        <div >
            <div >
                <div style={{display: 'flex', gap: '40px'}}>
                    <div>
                        <p>Logo</p>   
                        <Upload
                            name="file"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={uploadToS3}
                            beforeUpload={beforeUpload}
                        >
                            {imageUrl ? <img src={assetImage(imageUrl)} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </div>

                    <div>
                        <p>Logo negative</p>   
                        <Upload
                            name="file"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={uploadSecondaryToS3}
                            beforeUpload={beforeUpload}
                        >
                            {secondaryImageUrl ? <img src={assetImage(secondaryImageUrl)} alt="secondary" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </div>

                    <div>
                        <p>Favicon</p>   
                        <Upload
                            name="file"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={uploadFaviconToS3}
                            beforeUpload={beforeUpload}
                        >
                            {faviconImageUrl ? <img src={assetImage(faviconImageUrl)} alt="favicon" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </div>

                    
                </div>
            </div>
        </div>

        <div className="flex justify-start mt-8">
            <Button
                htmlType="submit"
                className="primary-button pointer-hover"
                style={{ backgroundColor: "#ff7b00", display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'auto', padding: '8px 44px' }}
            >
                <div className="primary-button-text">SAVE</div>
            </Button>
        </div>

       
    </Form>

    </>)
}

export default OnboardingBrand;