import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { AvailableRoutes } from "../../../pages/Routes";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { selectSelectedSubMenu, setSelectedSubMenu, setShowActivitySideBar } from "store/Navigation/NavigationStore";

const ActivityDashboard = ( {openExtraVar}: any ) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const whiteLabel = useSelector( selectWhiteLabel );
  const selectedSubMenu = useSelector( selectSelectedSubMenu);

  const renderActivitiesMenu = () => {
    return <>
      <div
        onClick={() => {
          navigate(AvailableRoutes.ALERT);
          dispatch(setSelectedSubMenu("insights"));
          dispatch(setShowActivitySideBar(true));
        }}
        className={`report-sidebar-item pointer-hover ${ openExtraVar? "res:flex" : "res:hidden" } `}
        style={{
          backgroundColor:
            selectedSubMenu === "insights"
              ? whiteLabel.activeColor
              : "transparent",
        }}
        key={"insights"}
        >
        <p>{"Insights"}</p>
      </div>

      <div
        onClick={() => {
          navigate(AvailableRoutes.ONBOARDING_ACCOUNT);
          dispatch(setSelectedSubMenu("onboarding"));
          dispatch(setShowActivitySideBar(true));
        }}
        className={`report-sidebar-item pointer-hover ${ openExtraVar? "res:flex" : "res:hidden" } `}
        style={{
          backgroundColor:
            selectedSubMenu === "onboarding"
              ? whiteLabel.activeColor
              : "transparent",
        }}
        key={"onboarding"}
        >
        <p>{"Onboarding"}</p>
      </div>
      
    </>

  };

  return (
    <>
      {renderActivitiesMenu()}
    </>
  );
};

export default React.memo(ActivityDashboard);
