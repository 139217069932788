import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from "react"
import { InfoCircleOutlined } from "@ant-design/icons";
import CustomTooltip from "../../tooltip/tooltip";

import { OnboardingLocation } from "../../../models/OnboardingLocation";
import { useNavigate } from "react-router-dom";
import { selectUserAccount } from '../../../store/Account/AccountStore';

import { UserRole } from "../../../models/User";

interface PacingProps {
    onboardingLocation: OnboardingLocation | undefined
}

export const OnboardingPacing = (props: PacingProps) => {
    const navigate = useNavigate();
    const user = useSelector(selectUserAccount);

    const [showAlwaysOnTooltip, setShowAlwaysOnTooltip] = useState(false);

    return ( <>
        <div className="flex items-end gap-x-12">

            { props.onboardingLocation && props.onboardingLocation.pacingStat === "verified" ? (
                
                
                <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                    <span>Verified</span>
                    <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#009051' }}></span>
                </div>
                
                
            ): (
                
                <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                    <span>Pending</span>
                    <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#FF5566' }}></span>
                </div>
                
            )}

            { user?.type === UserRole.Franchisor || user?.type === UserRole.Agency ? (
                
                <div 
                    onClick={() => navigate("/budget-approvals")}
                    className="primary-button w-[auto] pointer-hover h-full px-4"
                >
                    <div className="primary-button-text">VIEW BUDGETS</div>
                </div>
                
                
            ): (
                <CustomTooltip
                    open={showAlwaysOnTooltip}
                    onMouseEnter={() => setShowAlwaysOnTooltip(true)}
                    onMouseLeave={() => setShowAlwaysOnTooltip(false)}
                    title="Your budgets are created automatically when the media plan is verified."
                    placement="right-start"
                    arrow
                >
                    <InfoCircleOutlined
                        style={{ color: "black", fontSize: "20px", marginLeft: '15px', marginRight: "20px", marginBottom: "10px" }}
                    />
                </CustomTooltip>
            )}
        </div>
    </>)
}

export default OnboardingPacing;