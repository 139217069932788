import React, { useState } from "react"
import { OnboardingLocation } from "../../../models/OnboardingLocation";

interface TargetingProps {
    onboardingLocation: OnboardingLocation | undefined
}

export const OnboardingTargeting = (props: TargetingProps) => {
    
    return ( <>
        <div className="flex items-end gap-x-12">

            { props.onboardingLocation && props.onboardingLocation.targetingStat === "verified" ? (
                
                
                <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                    <span>Verified</span>
                    <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#009051' }}></span>
                </div>
                
                
            ): (
                
                <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                    <span>Pending</span>
                    <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#FF5566' }}></span>
                </div>
                
            )}
        </div>
    </>)
}

export default OnboardingTargeting;