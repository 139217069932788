import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OnboardingFranchise } from "../../models/OnboardingFranchise";
import services from "../../services";
import { RootState } from "../index";

export const fetchOnboardingFranchise = createAsyncThunk("onboarding-franchises/fetch", async () => {
  return services.onboardingFranchiseService.getOnboardingFranchise();
});

export const updateOnboardingFranchise = createAsyncThunk(
  "onboarding-franchises/update",
  async (updateOnboardingFranchise: OnboardingFranchise) => {
    return services.onboardingFranchiseService.updateOnboardingFranchise(updateOnboardingFranchise);
  }
);


export const onboardingFranchiseSlice = createSlice({
  name: "onboardingFranchise",
  initialState: {
    onboardingFranchise: undefined as OnboardingFranchise | undefined,
    isOnboardingFranchiseLoaded: false,
  },
  reducers: {
    setOnboardingFranchise: (state, { payload: onboardingFranchise }: PayloadAction<OnboardingFranchise>) => {
      state.onboardingFranchise = onboardingFranchise;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOnboardingFranchise.fulfilled, (state, action) => {
      console.log('Obtained the following config for onboarding');
      console.log(action.payload);
      state.onboardingFranchise = action.payload;
      state.isOnboardingFranchiseLoaded = true;
    });

    builder.addCase(fetchOnboardingFranchise.rejected, (state, action) => {
      state.isOnboardingFranchiseLoaded = true;
    });

    builder.addCase(updateOnboardingFranchise.fulfilled, (state, action) => {
      state.onboardingFranchise = action.payload;
    });
  },
});

export const selectOnboardingFranchise = (state: RootState): OnboardingFranchise | undefined =>
  state.onboardingFranchise.onboardingFranchise;

export const selectIsOnboardingFranchiseLoaded = (state: RootState): boolean =>
  state.onboardingFranchise.isOnboardingFranchiseLoaded;

export const { setOnboardingFranchise } = onboardingFranchiseSlice.actions;

export default onboardingFranchiseSlice.reducer;
