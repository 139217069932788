import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import {OnboardingAdLog} from './OnboardingAdLog';
import {OnboardingMediaPlan} from './OnboardingMediaPlan';
import {OnboardingPacing} from './OnboardingPacing';
import {OnboardingTargeting} from './OnboardingTargeting';

import { selectFranchiseLocations } from "../../../store/Location/LocationStore";
import { fetchOnboardingLocations, selectIsOnboardingLocationsLoaded, selectOnboardingLocations } from '../../../store/OnboardingLocation/OnboardingLocationStore';

import { ReactComponent as IconArrow } from "../../../assets/icons/arrow-down-black.svg";
import { Form, Select } from "antd";
import { OnboardingLocation } from '../../../models/OnboardingLocation';

export const StrategyMain = () => {

    const dispatch = useDispatch();
    
    const [accordionIndex, setAccordionIndex] = useState(null as null | number);

    const [allLocations, setAllLocations] = useState([] as any[]);
    const [selectedLocation, setSelectedLocation] = useState(undefined as undefined | string);
    const [selectedOnboardingLocation, setSelectedOnboardingLocation] = useState(undefined as undefined | OnboardingLocation);
    const [form] = Form.useForm();

    const franchiseLocations = useSelector(selectFranchiseLocations);
    
    const isOnboardingLocationsLoaded = useSelector(selectIsOnboardingLocationsLoaded);
    const onboardingLocations = useSelector(selectOnboardingLocations);

    const onLocationChange = (value: string) => {
        setSelectedLocation(value);
    };

    useEffect(() => {
        if( franchiseLocations.length > 0 && onboardingLocations ){
            
            const dynamicLocations = franchiseLocations.map((location) => {return {label: location.name, value: location.id, active: location.active}});

            // Sort only the dynamicLocations array
            dynamicLocations.sort((a, b) => {
              const labelA = a.label.toLowerCase();
              const labelB = b.label.toLowerCase();

              if (labelA < labelB) {
                  return -1;
              }
              if (labelA > labelB) {
                  return 1;
              }
              return 0;
            });

            const activeLocations = dynamicLocations.filter(location => location.active);
            //const filteredLocations = activeLocations.filter((location) => onboardingLocations.some((entry) => entry.locationId === location.value));
            const filteredLocations = activeLocations;
            
            setAllLocations(filteredLocations);

            form.setFieldsValue({ location: filteredLocations[0] ? filteredLocations[0].value : null  });
            setSelectedLocation(filteredLocations[0] ? filteredLocations[0].value : undefined);
        }
    },[franchiseLocations, onboardingLocations])


    useEffect(() => {
        if( selectedLocation && onboardingLocations ){
            const foundObLocation = onboardingLocations.find((obLocation) => obLocation.locationId === selectedLocation );
            setSelectedOnboardingLocation(foundObLocation); 
        }
    }, [selectedLocation, onboardingLocations])

    const createAccordion = (onboardingLocation: OnboardingLocation | undefined) => {
        return [
            {
                name: 'Media plan',
                component: <OnboardingMediaPlan onboardingLocation={onboardingLocation} />,
            },
            {
                name: 'Pacing',
                component: <OnboardingPacing onboardingLocation={onboardingLocation}/>,
            },
            {
                name: 'Ad log',
                component: <OnboardingAdLog onboardingLocation={onboardingLocation}/>,
            },
            {
                name: 'Targeting',
                component: <OnboardingTargeting onboardingLocation={onboardingLocation}/>,
            },
        ];
    }

    const toggleAccordion = (index : number | null) => {
        setAccordionIndex(accordionIndex === index ? null : index); // Toggle the accordion
    };

    return ( <>
        <div className="flex items-start justify-between w-5/6 mb-6">
            <h1 className='mb-6 text-2xl'>Strategy</h1>

            <div className='flex items-center'>
                <span className='mr-4 text-lg'>Outlet:</span>
                <Form
                    form={form}
                    name="basic"
                    autoComplete="off"
                >
                    <Form.Item
                        className="!ml-0 mb-0"
                        name="location"
                        style={{  width: "183px" }}
                    >
                        <Select
                            showArrow
                            value={selectedLocation}
                            onChange={onLocationChange}
                            options={allLocations}
                        />
                    </Form.Item>
                </Form>
            </div>
            
        </div>
        
        <div className="space-y-8">
            
            { selectedLocation && (
                createAccordion(selectedOnboardingLocation).map((item, index) => (
                    <div key={index} className="">
                        <button
                            onClick={() => toggleAccordion(index)}
                            className="flex items-center justify-between w-5/6 p-2 bg-white rounded-2xl hover:bg-gray-100 focus:outline-none px-7"
                            style={{ borderWidth: '1px', borderColor: 'black' }}
                        >
                            
                            <span className='text-lg'>{item.name}</span>
    
                            <span
                                className='flex items-center'
                                style={{
                                    transform: accordionIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                }}
                            >
                                <IconArrow />
                            </span>
                            
                        </button>
                        {accordionIndex === index && (
                            <div className="w-full py-6">{item.component}</div>
                        )}
                    </div>
                ))
            )}
        </div>
    </>)
}

export default StrategyMain;