import { setSelectedMainMenu, setSelectedSubMenu } from "store/Navigation/NavigationStore";
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router-dom";

import { AccountMain } from "./account/AccountMain";
import { AssetsMain } from "./assets/AssetsMain";
import { DistributionMain } from "./distribution/DistributionMain";
import { StrategyMain } from "./strategy/StrategyMain";

import { fetchOnboardingFranchise, selectIsOnboardingFranchiseLoaded } from "../../store/OnboardingFranchise/OnboardingFranchiseStore";
import { fetchOnboardingLocations, selectIsOnboardingLocationsLoaded, selectOnboardingLocations } from '../../store/OnboardingLocation/OnboardingLocationStore';

import "./styles.css";
import { useEffect } from "react";

export const OnboardingMainPage = (): JSX.Element => {
  
  const dispatch = useDispatch();

  dispatch( setSelectedMainMenu("activity") );
  dispatch( setSelectedSubMenu("onboarding") );

  const isOnboardingFranchiseLoaded = useSelector( selectIsOnboardingFranchiseLoaded );
  const isOnboardingLocationsLoaded = useSelector(selectIsOnboardingLocationsLoaded);
  
  useEffect(() => {
    if( !isOnboardingFranchiseLoaded ){
      dispatch(fetchOnboardingFranchise());
    }
  }, [isOnboardingFranchiseLoaded])

  useEffect(() => {
    if( !isOnboardingLocationsLoaded){
        dispatch( fetchOnboardingLocations() );
    }
}, [isOnboardingLocationsLoaded])

  const {section} = useParams();

  return (<> 
    <div className="block w-full onboarding-main">
      { section === 'account' && (
        <AccountMain />
      )}
      { section === 'distribution' && (
        <DistributionMain />
      )}
      { section === 'strategy' && (
        <StrategyMain />
      )}
      { section === 'assets' && (
        <AssetsMain />
      )}
    </div>
    </>);
};
