import { selectWhiteLabel } from "../../store/WhiteLabel/WhiteLabelStore";
import { setSelectedMainMenu, setSelectedSubMenu, setShowActivitySideBar } from "store/Navigation/NavigationStore";
import { useDispatch, useSelector } from 'react-redux'
import { assetImage } from "../../utility/whiteLabelManager";
import { selectLocations,selectFranchiseLocations } from "../../store/Location/LocationStore";
import { selectUserAccount } from "../../store/Account/AccountStore";
import FeedAlert from "./FeedAlert";
import "./styles.css";
import { selectFranchise } from 'store/Franchise/FranchiseStore'
import { UserRole } from "../../models/User";

export const FeedPage = (): JSX.Element => {
  
  const whiteLabel = useSelector( selectWhiteLabel );
  const franchise = useSelector(selectFranchise);
  const locations = useSelector(selectFranchiseLocations);
  //const locations = useSelector(selectLocations);
  const dispatch = useDispatch();
  const user = useSelector(selectUserAccount);

  dispatch( setSelectedMainMenu("activity") );
  dispatch( setSelectedSubMenu("insights") );
  dispatch( setShowActivitySideBar(true));

  return (<> 
    <div className="block mb-[11rem]">

        <div className="flex  res:flex-wrap max-h-[100%] md:max-w-[520px] lg:max-w-[749px] bg-white mx-2 shadow-[0px_0px_8px_0px_#15072566] rounded-[8px]  mb-8" id="headerFeedAlert" >
            <div className="logo bg-[--color-primary] p-[34px_30px] rounded-[8px_0_0_8px] res:rounded-[8px_8px_0_0] res:w-full res:text-center">
            { whiteLabel.logoPath != ""
                ? <img className=" w-[115px] brightness-[999]" src={ assetImage( whiteLabel.logoPath ) } alt="logo" />
                : <div className="text-white text-[30px]"></div>
            }
            </div>

            <div id="" className="py-4 px-8 [&>*]:mb-0 w-full">
                <p className=" font-bold text-[14px]">Welcome back</p>
                <br></br>
                <p>Brand: {franchise?.name}</p>
                <p>Outlets:  
                    {(user?.type === UserRole.Franchisor || user?.type === UserRole.Agency) ? ( 
                        " "+locations.length
                    ) : ( locations
                        .filter(location => user?.email == location.ownerEmail)
                        .map((location,idx) => (
                        <span key={location.id}>{idx == 0 ? "" : ","} {location.name}</span>
                    )) )}
                </p>
            </div>
        </div>

        <div className="flex flex-wrap justify-between res:mb-[6rem] max-h-[100%] overflow-hidden  md:max-w-[520px] lg:max-w-[749px] md:w-[520px] lg:w-[749px]">
            <div className="basis-[44%] res:basis-full overflow-auto h-[72.8vh] res:h-auto px-2 customScrollBar pb-5">
                <div className="px-[17px] mb-[18px]">
                    <h2>Recent</h2>
                </div>
                <FeedAlert alertType="recent" />
            </div>

            <div className="divider mt-[20%] h-[300px] w-[1px] res:hidden bg-[#42424280] "></div>

            <div className="basis-[44%] res:basis-full overflow-auto h-[72.8vh] res:h-auto px-2 customScrollBar pb-5">
                <div className="px-[17px] mb-[18px]">
                    <h2>Upcoming</h2>
                </div>
                <FeedAlert alertType="upcoming" />
            </div>
        </div>
    </div>
    </>);
};
