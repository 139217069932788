import { useSelector } from 'react-redux'
import React, {useEffect, useState} from "react";

import { ReactComponent as IconCheck } from "../../assets/icons/check-circle.svg";
import { selectUserAccount } from "../../store/Account/AccountStore";
import { ReactComponent as IconAccount } from "../../assets/icons/account.svg";
import { ReactComponent as IconAssets } from "../../assets/icons/assets.svg";
import { ReactComponent as IconDistribution } from "../../assets/icons/distribution.svg";
import { ReactComponent as IconStrategy } from "../../assets/icons/strategy.svg";
import { ReactComponent as IconLayers } from "../../assets/icons/layers.svg";
import { ReactComponent as IconAlertTriangle } from "../../assets/icons/alert-triangle.svg";
import { ReactComponent as IconCheckCircle } from "../../assets/icons/check-circle.svg";

import { AvailableRoutes } from "../../pages/Routes";
import { selectWhiteLabel } from "../../store/WhiteLabel/WhiteLabelStore";
import { selectOnboardingFranchise, selectIsOnboardingFranchiseLoaded } from '../../store/OnboardingFranchise/OnboardingFranchiseStore';

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import "./styles.css";
import { selectOnboardingLocations } from '../../store/OnboardingLocation/OnboardingLocationStore';
import { OnboardingLocation } from '../../models/OnboardingLocation';

export const OnboardingSidebar = ({ OpenOnboardingSideBar, isOpen }: any) => {

    const navigate = useNavigate();
    const {section} = useParams();
    const whiteLabel = useSelector(selectWhiteLabel);
    const toggleSideBar = () => {
        OpenOnboardingSideBar(isOpen)
    }

    const isOnboardingFranchiseLoaded = useSelector(selectIsOnboardingFranchiseLoaded);
    const onboardingFranchise = useSelector(selectOnboardingFranchise);
    const onboardingLocations = useSelector(selectOnboardingLocations);
    const account = useSelector(selectUserAccount);

    /*
        TODO: Determine if each section is verified or pending based on the values stored in the onboardingFranchise and onBoardingLocation.
    */

    //Account always displays as verified.
    const accountStatus = true;

    //Check if all the desired onboarding franchise status are verified to determine the status of "distribution"
    const distributionStatus = 
        onboardingFranchise &&
        onboardingFranchise.locationsStat === "verified" &&
        onboardingFranchise.gadsStat === "verified" &&
        onboardingFranchise.madsStat === "verified" &&
        onboardingFranchise.dspStat === "verified" &&
        onboardingFranchise.ganaStat === "verified"
    ;

    //Check all the onboarding locations obtained.
    //Check if any onboarding location at all has a pending value.
    //Returns TRUE if all the locations have verified values in all columns.
    //Returns FALSE if any location has a pending status.
    const hasPendingStat = (onboardingLocations: OnboardingLocation[]): boolean => {
        return !onboardingLocations.some(location => 
          location.mediaPlanStat === "pending" ||
          location.pacingStat === "pending" ||
          location.adLogStat === "pending" ||
          location.targetingStat === "pending"
        );
    };
    const strategyStatus = onboardingLocations && hasPendingStat(onboardingLocations);

    //How to determine if assets is verified?
    const assetsStatus = 
        onboardingFranchise &&
        onboardingFranchise.lpDomainStat === "verified" 



    

    const renderOnboardingOptions = () =>{
        return(
            <>
                <div 
                    className={`flex relative mb-[40px] group active items-center cursor-pointer w-full`}
                    onClick={ () => {navigate(AvailableRoutes.ONBOARDING_ACCOUNT)} }
                >
                    <div className="icon w-[38px] h-[34px] flex items-center justify-center">
                        <IconAccount className={ section === 'account' ? 'onboarding_menu_active' : ''}/>
                    </div>
                    
                    <div className="flex items-center flex-col ml-[30px]"> 
                        <span className={ section === 'account' ? 'text-[--color-primary]' : ''}>Account</span>
                    </div>

                    { ( accountStatus) ? (
                        <span className='absolute top-0 bottom-0 right-0 flex items-center px-2 py-1 rounded-l-lg' style={{ backgroundColor: '#009051' }}>
                            <IconCheckCircle/>
                        </span>
                    ): (
                        <span className='absolute top-0 bottom-0 right-0 flex items-center justify-center px-2 py-1 rounded-l-lg' style={{ backgroundColor: '#FF5566' }}>
                            <IconAlertTriangle/>
                        </span>
                    )}
                </div>

                <div 
                    className={`flex relative mb-[40px] group active items-center cursor-pointer w-full`}
                    onClick={ () => {navigate(AvailableRoutes.ONBOARDING_DISTRIBUTION)} }
                >
                    <div className="icon w-[38px] h-[34px] flex items-center justify-center">
                        <IconDistribution className={ section === 'distribution' ? 'onboarding_menu_active' : ''}/>
                    </div>
                    
                    <div className="flex items-center flex-col ml-[30px]"> 
                        <span className={ section === 'distribution' ? 'text-[--color-primary]' : ''}>Distribution</span>
                    </div>

                    { (distributionStatus) ? (
                        <span className='absolute top-0 bottom-0 right-0 flex items-center px-2 py-1 rounded-l-lg' style={{ backgroundColor: '#009051' }}>
                            <IconCheckCircle/>
                        </span>
                    ): (
                        <span className='absolute top-0 bottom-0 right-0 flex items-center justify-center px-2 py-1 rounded-l-lg' style={{ backgroundColor: '#FF5566' }}>
                            <IconAlertTriangle/>
                        </span>
                    )}
                </div>

                <div 
                    className={`flex relative mb-[40px] group active items-center cursor-pointer w-full`}
                    onClick={ () => {navigate(AvailableRoutes.ONBOARDING_STRATEGY)} }
                >
                    <div className="icon w-[38px] h-[34px] flex items-center justify-center">
                        <IconStrategy className={ section === 'strategy' ? 'onboarding_menu_active' : ''}/>
                    </div>
                    
                    <div className="flex items-center flex-col ml-[30px] cursor-pointer"> 
                        <span className={ section === 'strategy' ? 'text-[--color-primary]' : ''}>Strategy</span>
                    </div>

                    { (strategyStatus) ? (
                        <span className='absolute top-0 bottom-0 right-0 flex items-center px-2 py-1 rounded-l-lg' style={{ backgroundColor: '#009051' }}>
                            <IconCheckCircle/>
                        </span>
                    ): (
                        <span className='absolute top-0 bottom-0 right-0 flex items-center justify-center px-2 py-1 rounded-l-lg' style={{ backgroundColor: '#FF5566' }}>
                            <IconAlertTriangle/>
                        </span>
                    )}
                </div>

                <div 
                    className={`flex relative mb-[40px] group active items-center cursor-pointer w-full`}
                    onClick={ () => {navigate(AvailableRoutes.ONBOARDING_ASSETS)} }
                >
                    <div className="icon w-[38px] h-[34px] flex items-center justify-center">
                        <IconAssets className={ section === 'assets' ? 'onboarding_menu_active' : ''}/>
                    </div>
                    
                    <div className="flex items-center flex-col ml-[30px]"> 
                        <span className={ section === 'assets' ? 'text-[--color-primary]' : ''}>Assets</span>
                    </div>

                    { (assetsStatus) ? (
                        <span className='absolute top-0 bottom-0 right-0 flex items-center px-2 py-1 rounded-l-lg' style={{ backgroundColor: '#009051' }}>
                            <IconCheckCircle/>
                        </span>
                    ): (
                        <span className='absolute top-0 bottom-0 right-0 flex items-center justify-center px-2 py-1 rounded-l-lg' style={{ backgroundColor: '#FF5566' }}>
                            <IconAlertTriangle/>
                        </span>
                    )}
                </div>
            </>
            
        );
    }

    return <div className={`activeChannelDashBoard  pl-[24px] pr-0 py-[50px] overflow-hidden res:hidden` }> 

    <div className="flex  mb-[25px] group active cursor-pointer "
        onClick={toggleSideBar}
    >
        <div className="icon w-[38px] text-center active-icon">
            <IconLayers width={"30px"} />
        </div>

        <div className="flex items-center flex-col ml-[30px] cursor-pointer"> 
            <span className='text-xl'>Onboarding</span>
        </div>
        
    </div>
    
    <div className="mt-10" >
        { renderOnboardingOptions() }
    </div>
    

    
</div>

}