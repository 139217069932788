import { Franchise } from "../models/Franchise";
import { addDaysToDate } from "../utility/time";
import RestService from "./restService";
import { Audience } from "../models/Audience"
import {DemographicsFranchise} from "../models/Demographics";

export interface IUpdateFranchise {
  gadsCustomerId?: string;
  fadsAccountId?: string;
  gAnalyticsId?: string;
  stiristaId?: string;
}

//Used to determine which services this franchise has access to.
export interface FranchiseCredentialValidation{
  googleAds: boolean;
  googleAnalytics: boolean;
  meta: boolean;
  loaded: boolean;
}

//The expected data in the franchises file. All attributes are required.
//Used when importing rows from csv or xlsx
export interface FranchiseRowSchema {
  name: string,
  appId: string,
  active: string, //this doesn't seem to be useful since there is no active attribute in the DB...
  gadsCustomerId: string,
  fadsCampaignId: string,
  gAnalyticsId: string,
  //__rowNum__: number,
}

export interface FranchiseGAnalyticsRow {
  key: string,
  name: string,
  sessions: number,
  users: number,
  aet: number,
  conversions: number,
}

//Individual report for each date in a channel.
export interface FranchiseOmniChannelDate{
  date: String;
  impressions: number;
  pmConversions: number;
  clicks: number;
  ctr: number;
  cpc: number;
  cpl: number;
  spend: number;
}

//Individual report for each date in a channel.
export interface CPLDate{
  name: string;
  value: number;
}

export interface FranchiseOmniChannelLocation{
  key: string; //location id
  name: string; //location name
  dates: FranchiseOmniChannelDate[]; //list of dates for this location
}

//Individual SYSTEM report for a date
export interface SystemReportDate{
  date: string;
  impressions: number;
  clicks: number;
  ctr: number;
  cpc: number;
  spend: number;
  dateCount: number;
}

//Individual report for each channel.
export interface FranchiseOmniChannelRow{
  key: string;
  name: string;
  impressions: number;
  pmConversions:number;
  clicks: number;
  ctr: number;
  cpc: number;
  cpl: number;
  spend: number;
  dates: FranchiseOmniChannelDate[];
  locationReports: FranchiseOmniChannelLocation[];
  systemReport: FranchiseOmniChannelLocation;
}

//Main container for the omnichannel reports.
//Contains the channelReports and the summarized data.
export interface FranchiseOmniChannelMain{
  channelReports: FranchiseOmniChannelRow[];
  impressions: number;
  pageViews: number;
  conversions: number;
  pmConversions:number;
  clicks: number;
  ctr: number;
  spend: number;
  cpc: number;
  cpl: number;
}

export interface FranchiseGadsRow {
  key: string;
  locationId: string;
  name: string;
  clicks: number;
  conversions: number;
  impressions: number;
  ctr: number;
  cpc: number;
  cost: number;
  campaignId: string;
}

export interface FranchiseKeyWordPerformance {
  key: string;
  word: string;
  ctr: number;
  cost: number;
  impressions: number; 
}

export interface FranchiseCustomReportsRow {
  key: string;
  channelId: string;
  locationId: string;
  name: string;
  channelName: string;
  impressions: number;
  ctr?: number;
  cpc?: number;
  cost: number;
  clicks: number;
  conversions: number;
  campaignId: string;
}

export interface FranchiseCustomKeywordsRow {
  key: string;
  channelId: string;
  locationId: string;
  keyword: string;
  channelName: string;
  impressions: number;
  ctr: number;
  cost: number;
  adGroup: string;
}

export interface FranchiseCustomCreativesRow {
  key: string;
  channelId: string;
  locationId: string;
  creative: string;
  channelName: string;
  impressions: number;
  ctr?: number; // set null safety
  clicks: number; // adding clicks
  cost: number;
}

export interface FranchiseGadsSchema {
  campaigns: FranchiseGadsRow[];
  nextPageToken: string;
  error: string | undefined;
  lastStartDate: string | undefined;
  lastEndDate: string | undefined;
  lastOrderBy: string | undefined;
  lastPageToken: string | undefined;
}

/*export interface FranchiseGadsKeywordCreativeSchema {
  campaigns: FranchiseGadsRow[];
  nextPageToken: string;
  error: string | undefined;
  lastOrderBy: string | undefined;
  lastPageToken: string | undefined;
  lastDateRange: string | undefined;
  lastLocation: string | undefined;
}*/

export interface FranchiseGadsKeyword {
  key: string;
  word: string;
  ctr: number;
  cost: number;
  impressions: number; 
}

export interface FranchiseGadsKeywordCreativeSchema {
  keyWords: FranchiseGadsKeyword[],
  error: string | undefined,
  lastDateRange: string | undefined,
  lastOrderBy: string | undefined,
  lastLocation: string | undefined,
  lastPageToken: string | undefined;
  nextPageToken: string,
  name: string,
}

export interface FranchiseFadsSchema {
  campaigns: FranchiseGadsRow[];
  nextPageToken: string;
  prevPageToken: string;
  hasNextPage: Boolean;
  hasPrevPage: Boolean;
  error: string | undefined;
  lastStartDate: string | undefined;
  lastEndDate: string | undefined;
  lastOrderBy: string | undefined;
  lastPageToken: string | undefined;
}

export interface FranchiseCustomReportsSchema {
  channels: FranchiseCustomReportsRow[];
  error: string | undefined;
  lastStartDate: string | undefined;
  lastEndDate: string | undefined;
  lastOrderBy: string | undefined;
}

export interface FranchiseCustomKeywordsSchema {
  keywords: FranchiseCustomKeywordsRow[];
  error: string | undefined;
  lastLocation: string | undefined;
  lastOrderBy: string | undefined;
}

export interface FranchiseCustomCreativesSchema {
  creatives: FranchiseCustomCreativesRow[];
  error: string | undefined;
  lastLocation: string | undefined;
  lastOrderBy: string | undefined;
}

export interface FranchiseGASchema {
  landing: FranchiseGAnalyticsRow[];
  error: string | undefined;
  lastStartDate: string | undefined;
  lastEndDate: string | undefined;
  lastOrderBy: string | undefined;
  lastConversion: String[] | undefined;
}

export interface FranchiseOmniChannelSchema{
  omniChannel: FranchiseOmniChannelMain;
  error: string | undefined;
  lastChannelName: string | undefined;
  lastDateRange: string | undefined;
  lastStartDate: string | undefined;
  lastEndDate: string | undefined;
  
}

export default class FranchiseService {
  constructor(protected readonly api: RestService) { }

  async updateFranchise(updateFranchise: IUpdateFranchise): Promise<Franchise> {
    return await this.api.put<Franchise>("/franchises", updateFranchise);
  }

  async getGoogleAdsReports(dateRange?: string, startDate?: string, endDate?: string, orderBy?: string, pageToken?: string, locations?: string[], userId?: string): Promise<FranchiseGadsSchema> {

    const query: string[] = [];
    if (dateRange) {
      query.push(`dateRange=${dateRange}`);
    }
    if (startDate) {
      query.push(`startDate=${startDate}`);
    }
    if (endDate) {
      query.push(`endDate=${endDate}`);
    }
    if (orderBy) {
      query.push(`orderBy=${orderBy}`);
    }
    if (pageToken) {
      query.push(`pageToken=${pageToken}`);
    }
    if(locations && locations?.length > 0){
      query.push(`withLocations=${true}`)
    }
    if (userId) {
      query.push(`userId=${userId}`);
    }
    const res = await this.api.get<any>(`/franchises/reports/google${query.length ? '?' + query.join('&') : ''}`);

    if (res.error) {
      return {
        campaigns: [],
        nextPageToken: '',
        error: res.error,
        lastStartDate: startDate || addDaysToDate(new Date(), -28).toISOString().split('T')[0],
        lastEndDate: endDate,
        lastOrderBy: orderBy,
        lastPageToken: pageToken
      };
    }

    return {
      campaigns: res.results,
      nextPageToken: res.nextPageToken,
      error: undefined,
      lastStartDate: startDate || addDaysToDate(new Date(), -28).toISOString().split('T')[0],
      lastEndDate: endDate,
      lastOrderBy: orderBy,
      lastPageToken: pageToken
    };
  }

  /*async getGoogleAdsKeywordCreativeReports(orderBy?: string, pageToken?: string, dateRange?: string, location?: string): Promise<FranchiseGadsKeywordCreativeSchema> {
    const query: string[] = [];
    if (orderBy) {
      query.push(`orderBy=${orderBy}`);
    }
    if (pageToken) {
      query.push(`pageToken=${pageToken}`);
    }
    if (dateRange) {
      query.push(`dateRange=${dateRange}`);
    }
    if (location) {
      query.push(`location=${location}`);
    }
    const res = await this.api.get<any>(`/franchises/reports/google-keyword-creative${query.length ? '?' + query.join('&') : ''}`);

    if (res.error) {
      return {
        campaigns: [],
        nextPageToken: '',
        error: res.error,
        lastOrderBy: orderBy,
        lastPageToken: pageToken,
        lastDateRange: dateRange,
        lastLocation: location
      };
    }

    return {
      campaigns: res.results,
      nextPageToken: res.nextPageToken,
      error: undefined,
      lastOrderBy: orderBy,
      lastPageToken: pageToken,
      lastDateRange: dateRange,
      lastLocation: location
    };
  }*/

  async getGoogleAdsKeywordCreativeReports(orderBy?: string, pageToken?: string, dateRange?: string, location?: string): Promise<FranchiseGadsKeywordCreativeSchema> {
    const query: string[] = [];
    if (orderBy) {
      query.push(`orderBy=${orderBy}`);
    }
    if (pageToken) {
      query.push(`pageToken=${pageToken}`);
    }
    if (dateRange) {
      query.push(`dateRange=${dateRange}`);
    }
    if (location) {
      query.push(`location=${location}`);
    }
    const res = await this.api.get<any>(`/franchises/reports/google-keyword-creative${query.length ? '?' + query.join('&') : ''}`);

    if (res.error) {
      return {
        keyWords: [],
        name: res.name,
        error: res.error,
        lastOrderBy: orderBy,
        lastDateRange: dateRange,
        lastLocation: location,
        nextPageToken: '',
        lastPageToken: pageToken,
      };
    }

    return {
      keyWords: res.results,
      name: res.name,
      error: undefined,
      lastOrderBy: orderBy,
      lastDateRange: dateRange,
      lastLocation: location,
      nextPageToken: res.nextPageToken,
      lastPageToken: pageToken,
    };
  }

  async getFacebookAdsReports( dateRange?: string, startDate?: string, endDate?: string, orderBy?: string, pageToken?: string, pageDir?: string, locations?: string[], userId?: string): Promise<FranchiseFadsSchema> {
    const query: string[] = [];
    if (dateRange) {
      query.push(`dateRange=${dateRange}`);
    }
    if (startDate) {
      query.push(`startDate=${startDate}`);
    }
    if (endDate) {
      query.push(`endDate=${endDate}`);
    }
    if (orderBy) {
      query.push(`orderBy=${orderBy}`);
    }
    if (pageToken) {
      query.push(`pageToken=${pageToken}`);
    }
    if (pageDir) {
      query.push(`pageDir=${pageDir}`);
    }
    if(locations && locations?.length > 0){
      query.push(`withLocations=${true}`)
    }
    if (userId) {
      query.push(`userId=${userId}`);
    }
    const res = await this.api.get<any>(`/franchises/reports/facebook${query.length ? '?' + query.join('&') : ''}`);

    if (res.error) {
      return {
        campaigns: [],
        nextPageToken: '',
        prevPageToken: '',
        hasPrevPage: false,
        hasNextPage: false,
        error: res.error,
        lastStartDate: startDate || addDaysToDate(new Date(), -28).toISOString().split('T')[0],
        lastEndDate: endDate,
        lastOrderBy: orderBy,
        lastPageToken: pageToken
      };
    }

    return {
      campaigns: res.results,
      nextPageToken: res.nextPageToken,
      prevPageToken: res.prevPageToken,
      hasPrevPage: res.hasPrevPage,
      hasNextPage: res.hasNextPage,
      error: undefined,
      lastStartDate: startDate || addDaysToDate(new Date(), -28).toISOString().split('T')[0],
      lastEndDate: endDate,
      lastOrderBy: orderBy,
      lastPageToken: pageToken
    };
  }

  async getCustomChannelReports(dateRange?: string, startDate?: string, endDate?: string, orderBy?: string, channelId?: string, locations?: string[], userId?: string): Promise<FranchiseCustomReportsSchema> {
    const query: string[] = [];
    if (dateRange) {
      query.push(`dateRange=${dateRange}`);
    }
    if (startDate) {
      query.push(`startDate=${startDate}`);
    }
    if (endDate) {
      query.push(`endDate=${endDate}`);
    }
    if (orderBy) {
      query.push(`orderBy=${orderBy}`);
    }
    if (channelId) {
      query.push(`channelId=${channelId}`);
    }
    if(locations && locations?.length > 0){
      query.push(`withLocations=${true}`)
    }
    if (userId) {
      query.push(`userId=${userId}`);
    }

    const res = await this.api.get<any>(`/franchises/reports/custom-reports${query.length ? '?' + query.join('&') : ''}`);

    if (res.error) {
      return {
        channels: [],
        error: res.error,
        lastStartDate: startDate || addDaysToDate(new Date(), -28).toISOString().split('T')[0],
        lastEndDate: endDate,
        lastOrderBy: orderBy,
      };
    }

    return {
      channels: res.results,
      error: undefined,
      lastStartDate: startDate || addDaysToDate(new Date(), -28).toISOString().split('T')[0],
      lastEndDate: endDate,
      lastOrderBy: orderBy,
    };
  }

  async getCustomKeywordReports(channel: string, location: string, orderBy: string): Promise<FranchiseCustomKeywordsSchema> {
    const query: string[] = [];
    if (channel) {
      query.push(`channel=${channel}`);
    }
    if (location) {
      query.push(`location=${location}`);
    }
    if (orderBy) {
      query.push(`orderBy=${orderBy}`);
    }

    const res = await this.api.get<any>(`/franchises/reports/custom-keywords${query.length ? '?' + query.join('&') : ''}`);

    if (res.error) {
      return {
        keywords: [],
        error: res.error,
        lastLocation: location,
        lastOrderBy: orderBy,
      };
    }

    return {
      keywords: res.results,
      error: undefined,
      lastLocation: location,
      lastOrderBy: orderBy,
    };
  }

  async getCustomCreativeReports(channel: string, location: string, orderBy: string): Promise<FranchiseCustomCreativesSchema> {
    const query: string[] = [];
    if (channel) {
      query.push(`channel=${channel}`);
    }
    if (location) {
      query.push(`location=${location}`);
    }
    if (orderBy) {
      query.push(`orderBy=${orderBy}`);
    }

    const res = await this.api.get<any>(`/franchises/reports/custom-creatives${query.length ? '?' + query.join('&') : ''}`);

    if (res.error) {
      return {
        creatives: [],
        error: res.error,
        lastLocation: location,
        lastOrderBy: orderBy,
      };
    }

    const results = res.results.map((row: FranchiseCustomCreativesRow) => {
      const ctr = row.clicks === 0 ? 0 : (row.clicks / row.impressions);

      return {...row, ctr};
    });

    //! To orderBy the data by ctr because the api doesn't do anymore
    if(orderBy === 'ctr') {
      results.sort((a: FranchiseCustomCreativesRow, b: FranchiseCustomCreativesRow) => b.ctr! - a.ctr!);
    }

    return {
      creatives: results,
      error: undefined,
      lastLocation: location,
      lastOrderBy: orderBy,
    };
  }

  async getGAnalyticsConversionEvents(): Promise<String[]> {
    const res = await this.api.get<any>(`/franchises/reports/conversions`);
    if (res.details) {
      console.log(res.details);
      return [];
    }
    return res.results;
  }

  async getGAnalyticsReports(startDate?: string, endDate?: string, orderBy?: string, conversion?: String[]): Promise<FranchiseGASchema> {
    const query: string[] = [];
    if (startDate) {
      query.push(`startDate=${startDate}`);
    }
    if (endDate) {
      query.push(`endDate=${endDate}`);
    }
    if (orderBy) {
      query.push(`orderBy=${orderBy}`);
    }
    if (conversion) {
      query.push(`conversion=${conversion}`);
    }
    const res = await this.api.get<any>(`/franchises/reports/landing${query.length ? '?' + query.join('&') : ''}`);

    if (res.details) {
      return {
        landing: [],
        error: res.details,
        lastStartDate: startDate || addDaysToDate(new Date(), -28).toISOString().split('T')[0],
        lastEndDate: endDate,
        lastOrderBy: orderBy,
        lastConversion: conversion
      };
    }

    return {
      landing: res.results,
      error: undefined,
      lastStartDate: startDate || addDaysToDate(new Date(), -28).toISOString().split('T')[0],
      lastEndDate: endDate,
      lastOrderBy: orderBy,
      lastConversion: conversion
    };
  }

  async getOmniChannelReports(channelName?: string, dateRange?: string, startDate?: string, endDate?: string, userId?: string) : Promise<FranchiseOmniChannelSchema>{
    const query: string[] = []
    if (channelName) {
      query.push(`channelName=${channelName}`);
    }
    if (dateRange) {
      query.push(`dateRange=${dateRange}`);
    }
    if (startDate) {
      query.push(`startDate=${startDate}`);
    }
    if (endDate) {
      query.push(`endDate=${endDate}`);
    }

    if (userId) {
      query.push(`userId=${userId}`);
    }

    const res = await this.api.get<any>(`/franchises/reports/omnichannel${query.length ? '?' + query.join('&') : ''}`);
    // console.log("/franchises/reports/omnichannel response: ")
    // console.log(res);
    if (res.details) {
      return {
        omniChannel: {
          channelReports: [],
          impressions: 0,
          pageViews: 0,
          conversions: 0,
          pmConversions: 0,
          clicks: 0,
          ctr: 0,
          spend: 0,
          cpc: 0,
          cpl: 0
        },
        error: res.details,
        lastChannelName: channelName,
        lastDateRange: dateRange,
        lastStartDate: startDate,
        lastEndDate: endDate
      };
    }

    return{
      omniChannel: res.results,
      error: undefined,
      lastChannelName: channelName,
      lastDateRange: dateRange,
      lastStartDate: startDate,
      lastEndDate: endDate
    }
  }

  async getSimplifiedOmniChannelReports(channelName?: string, dateRange?: string, startDate?: string, endDate?: string, userId?: string) : Promise<FranchiseOmniChannelSchema>{
    const query: string[] = []
    if (channelName) {
      query.push(`channelName=${channelName}`);
    }
    if (dateRange) {
      query.push(`dateRange=${dateRange}`);
    }
    if (startDate) {
      query.push(`startDate=${startDate}`);
    }
    if (endDate) {
      query.push(`endDate=${endDate}`);
    }

    if (userId) {
      query.push(`userId=${userId}`);
    }

    const res = await this.api.get<any>(`/franchises/reports/simplified-omnichannel${query.length ? '?' + query.join('&') : ''}`);
    // console.log("/franchises/reports/omnichannel response: ")
    // console.log(res);
    if (res.details) {
      return {
        omniChannel: {
          channelReports: [],
          impressions: 0,
          pageViews: 0,
          conversions: 0,
          pmConversions: 0,
          clicks: 0,
          ctr: 0,
          spend: 0,
          cpc: 0,
          cpl: 0
        },
        error: res.details,
        lastChannelName: channelName,
        lastDateRange: dateRange,
        lastStartDate: startDate,
        lastEndDate: endDate
      };
    }

    return{
      omniChannel: res.results,
      error: undefined,
      lastChannelName: channelName,
      lastDateRange: dateRange,
      lastStartDate: startDate,
      lastEndDate: endDate
    }
  }

  async importFranchises(franchiseSchemaArray: FranchiseRowSchema[]): Promise<Franchise[]> {
    const apiResponse = await this.api.post<Franchise[]>("/franchises/import", franchiseSchemaArray);

    return apiResponse;
  }

  /**
   * Retrieve all franchises
   */
  async getAll() {
    return await this.api.get<Franchise[]>("/franchises/all");
  }

  /**
   * Save franchise audiences
   *
   * Save audiences list
   * @param franchiseId
   * @param audiences
   */
  async saveAudiences(franchiseId: string, audiences: Audience[]) {
    return await this.api.post(`/franchises/audiences/${franchiseId}`, audiences)
  }

  /**
   * Retrieve franchise
   */
  async getAudiences(): Promise<Audience[]> {
    const franchise = await this.api.get<DemographicsFranchise>('/franchises/info');
    return franchise?.audiences;
  }

  async getFranchise(): Promise<Franchise>{
    console.log("getting franchise")
    return await this.api.get<Franchise>("/franchises/info");
  }

  async getCredentialValidation(): Promise<FranchiseCredentialValidation>{
    console.log("getting franchise credential validation")
    return await this.api.get<FranchiseCredentialValidation>("/franchises/check-service-credentials");
  }

  async getFranchises(): Promise<Franchise[]>{
    console.log("getting franchisesssss")
    return await this.api.get<Franchise[]>("/franchises/get");
  }
}


