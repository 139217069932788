import "./UserPaymentMethods.css";
import { PaymentMethod } from "../../models/PaymentMethod";
import { capitalizeFirstLetter } from "../../utility/string";
import { ReactComponent as CreditCardIcon } from "../../assets/icons/CreditCardIcon.svg";
import { ReactComponent as Link2 } from "../../assets/icons/link-2.svg";
import { ReactComponent as IconCancel } from "../../assets/icons/cancel.svg";
import { useDispatch } from "react-redux";
import { removeUserPayment } from "../../store/Account/AccountStore";
import { useState } from "react";

interface SavedPaymentCardProps {
  method: PaymentMethod;
}

export const SavedPaymentCard = ({
  method,
}: SavedPaymentCardProps): JSX.Element => {
  const dispatch = useDispatch();
  const [showDelete, setShowDelete] = useState<boolean>(false);

  return (
    <div className="flex items-center justify-between bg-white mb-4 rounded-[10px] p-[13px_19px] shadow-[0px_0px_8px_0px_#00000040] col-span-3">
      {showDelete ? (
        <>
          <h2>Are you sure you want to remove this card?</h2>
          <h2
            className={classNames.deleteOption}
            onClick={() => dispatch(removeUserPayment(method.cardId))}
          >
            Yes
          </h2>
          <h2
            className={classNames.deleteOption}
            onClick={() => setShowDelete(false)}
          >
            No
          </h2>
        </>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center", width: "70%", }}>

            {
              method.type == "card" ? 
                <CreditCardIcon width={24} height={24}  />
              : <Link2   width={24} height={24}  />
            }
            

            <div>
              <h2 className="text-sm mb-0 ml-4">
                {capitalizeFirstLetter(method.cardBrand)}  { method.type == "card" ? `ending in ${method.last4}`: `` }
              </h2>

              { method.type == "card" && <span className="hidden res:block text-sm">
                Exp. {method.expirationMonth}/{method.expirationYear} 
              </span> }
            </div>
          </div>
          
            <div
              className=" res:!justify-end"
              style={{
                display: "flex",
                alignItems: "center",
                width: "30%",
                justifyContent: "flex-end",
              }}
            >
              { method.type == "card" && 
                <span className="res:hidden mr-7">
                  Exp. {method.expirationMonth}/{method.expirationYear}
                </span>
               }
              <IconCancel
                style={{ height: "100%", cursor: "pointer" }}
                onClick={() => setShowDelete(true)}
              />
            </div>
         
          
        </>
      )}
    </div>
  );
};

const classNames = {
  card: "user-payment-methods-saved-card",
  deleteOption: "user-payment-methods-saved-card-delete-option",
};
