import {Menu, MenuItem, Typography} from "@mui/material";
import React from "react";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { AvailableRoutes } from "../../../pages/Routes";
import IMenu from "./IMenu";
import { useDispatch, useSelector } from "react-redux";
import { setMenuType } from "../../../store/Menu/MenuStore";
import { MenuType } from "./MenuType";
import { selectUserAccount } from "../../../store/Account/AccountStore";


const DefaultMenu = (props: IMenu) => {
    var settings = ["My Account", "Switch Role", "Switch Brand", "Sign Out"];
    const dispatch = useDispatch();
    const { signOut } = useAuth();
    const navigate = useNavigate();
    const account = useSelector(selectUserAccount);
    
    if (account && !account.canSwitchRole) {
        settings = settings.filter(val => val !== 'Switch Role');
    }

    if (account && !account.canSwitchRole && !account.canSwitchBrand) {
        settings = settings.filter(val => val !== 'Switch Brand');
    }

    const manageSettings = (setting: string, event: any) => {
        switch (setting) {
            case "My Account":
                navigate(AvailableRoutes.BILLING);
                break;
            case "Sign Out":
                signOut();
                break;
            case "Switch Role":
                console.log('switch role requested');
                event.stopPropagation();
                dispatch(setMenuType(MenuType.Roles));
                break;
            case "Switch Brand":
                console.log("Switch brand requested")
                dispatch(setMenuType(MenuType.Franchises));
                event.stopPropagation();
                
                break;
            default:
                console.log("check you settings");
        }
    };

    return (
        <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={ props.anchorEl }
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(props.anchorEl)}
            onClose={props.closeMenuHandler}
        >
            {settings.map((setting) => (
            <MenuItem key={setting} onClick={props.closeMenuHandler}>
                <Typography
                    textAlign="center"
                    onClick={(e) => manageSettings(setting, e)}
                >
                    {setting}
                </Typography>
            </MenuItem>
            ))}
        </Menu>
    );
}

export default DefaultMenu;
