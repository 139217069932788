import "./UserPaymentMethods.css";
import { Button, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useStripe } from "@stripe/react-stripe-js";

import {
  addUserACHPayment,
  selectUserAccount,
  createSetUpIntent,
  selectClientSecret
} from "../../store/Account/AccountStore";
import { StripeCardElementOptions } from "@stripe/stripe-js";
import { ReactComponent as IconArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as IconArrowBack } from "../../assets/icons/orange-back-arrow.svg";
import { useState, useEffect } from "react";
import AlertMessage from "../alertMessage/AlertMessage";
import { selectWhiteLabel } from "../../store/WhiteLabel/WhiteLabelStore";

interface AddCardACHFormProps {
  onClose: () => void;
}




export const AddCardACHForm = ({ onClose }: AddCardACHFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const account = useSelector(selectUserAccount);
  const [openSuccessMsg, setOpenSuccessMsg] = useState<boolean>(false);
  const [openErrorMsg, setOpenErrorMsg] = useState<boolean>(false);
  const [requireConfirmation, setRequireConfirmation] = useState<boolean>(false);
  const clientSecret = useSelector( selectClientSecret );

  useEffect(() => {
    dispatch(createSetUpIntent());
  }, []);

  const saveACHData = async (values: any) => {

    console.log(clientSecret);

    if (!stripe) {
      return; 
    }

    if (clientSecret == null) {
      return;
    }

    // Get the ClientSecret of the intent. 
    const { account_holder_name, account_holder_email } = values;

    console.log(account_holder_name, account_holder_email);

    const {setupIntent, error} = await stripe.collectBankAccountForSetup(
      {
        clientSecret: clientSecret.clientSecret,
        params: {
          payment_method_type: 'us_bank_account',
          payment_method_data: {
            billing_details: {name: account_holder_name, email: account_holder_email},
          },          
        },
        expand: ['payment_method'],
      }      
    );

    if (error) {
      console.log(`Error code: ${error.code}`, error.message);
    } else if (setupIntent) {
      console.log('Setup status:', setupIntent.status);
      if (setupIntent.status === "requires_confirmation") {
        setRequireConfirmation(true);
      } else if (setupIntent.status === "requires_action") {

        // The next step is to call `verifyMicrodepositsForSetup`

      }
    }


 
    if (error) {
        console.log("Error al crear el método de pago ACH:", error);
        setOpenErrorMsg(true);
    } else {

    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setOpenErrorMsg(true);
    console.log("Failed:", errorInfo);
  };

  const handleAcceptButton = async() => {

    if (!stripe) {
      return; 
    }

    if (clientSecret == null) {
      return;
    }

    const {error, setupIntent} = await stripe.confirmSetup(
      {
        clientSecret: clientSecret.clientSecret, 
        redirect: "if_required"
      }
    );

    if (error) {
      console.log(`Error code: ${error.code}`, error.message);
    } else if (setupIntent) {
      console.log(setupIntent)
      if (setupIntent.status === "processing") {
        // The debit has been successfully submitted and is now processing
        
      } else if (
        setupIntent.status === "requires_action" &&
        setupIntent?.next_action?.type === 'verifyWithMicrodeposits'
      ) {
        // The payment must be verified with `verifyMicrodepositsForPayment`

      } else {
        // If is "succeeded" we save the paymentMethod or the setUpIntentId
        dispatch(addUserACHPayment(setupIntent.id));

        setOpenSuccessMsg(true);
        setTimeout(() => {
          onClose();
        }, 1000);

      }
    }
  }

  const whiteLabel = useSelector( selectWhiteLabel );

  console.log(requireConfirmation)
  return (
    
    <>
      <Form
        name="basic"
        onFinish={saveACHData}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="container-form"
        layout="vertical"
        style={{ maxWidth: "512px" }}
        hidden={requireConfirmation}
      >

          <Form.Item label="Name" name={"account_holder_name"} rules={[{required: true}]}>
              <Input className="res:py-3" placeholder="Account holder name"></Input>
          </Form.Item>

          <Form.Item label="Email" name={"account_holder_email"} rules={[{required: true}]}>
              <Input className="res:py-3" placeholder="Acount holder email"></Input>
          </Form.Item>


        {/* Buttons */}
        <div
          style={{
            display: "flex",
            marginTop: "40px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Form.Item className="res:basis-5/12">
            <Button
              className="inverted-general-button pointer-hover res:!w-full"
              onClick={onClose}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <div className="primary-button-text">BACK</div>
            </Button>
          </Form.Item>
          <Form.Item className="res:basis-5/12">
            <Button
              htmlType="submit"
              className="primary-button pointer-hover res:!w-full mt-0  hover:text-white"
              style={{ backgroundColor: whiteLabel.primaryColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <div className="primary-button-text">SAVE</div>
            </Button>
          </Form.Item>
        </div>
        <AlertMessage successMessage="Your Credit card has been saved successfully!" openScsMsg={openSuccessMsg} onScsClose={() => setOpenSuccessMsg(false)} openErrMsg={openErrorMsg} onErrClose={() => setOpenErrorMsg(false)}/>
      </Form>
        
      <div
        id="Confirmation section"
        hidden={!requireConfirmation}
      >
        <div className="md:w-1/2 w-full">
          <p>By clicking [accept], you authorize JBecca to debit the bank account specified above for any amount owed for charges arising from your use of JBecca’s services and/or purchase of products from JBecca, pursuant to JBecca’s website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to JBecca with 30 (thirty) days notice.</p>
        </div>

        <Form.Item className="res:basis-5/12">
            <Button
              className="inverted-general-button pointer-hover res:!w-full"
              onClick={handleAcceptButton}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <div className="primary-button-text">Accept</div>
            </Button>
        </Form.Item>
      </div>
    </>
    


    

  );
};