import React, { useState, useEffect } from "react";
import { Form, Input, Button, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { selectFranchise, updateFranchise } from "../../../store/Franchise/FranchiseStore";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { selectOnboardingFranchise, selectIsOnboardingFranchiseLoaded } from "../../../store/OnboardingFranchise/OnboardingFranchiseStore";

import AlertMessage from "../../alertMessage/AlertMessage";

export const OnboardingSystem = () => {
    
    const dispatch = useDispatch();
    const franchise = useSelector(selectFranchise);
    const onboardingFranchise = useSelector( selectOnboardingFranchise );
    const [openSuccessMsg, setOpenSuccessMsg] = useState<boolean>(false);
    const [openErrorMsg, setOpenErrorMsg] = useState<boolean>(false);
    const whiteLabel = useSelector( selectWhiteLabel );

    console.log(onboardingFranchise);
    console.log(franchise);

    const onFinish = (values: any) => {
        dispatch(updateFranchise({
            gadsCustomerId: values.gadsCustomerId,
            fadsAccountId: values.fadsAccountId,
            gAnalyticsId: values.gAnalyticsId,
            stiristaId: values.stiristaId,
        }));
        setOpenSuccessMsg(true);
    };
    
    const onFinishFailed = (errorInfo: any) => {
        setOpenErrorMsg(true);
        console.log("Failed:", errorInfo);
    };
    
    return ( <>
        { franchise && (
            <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className="w-7/12 container-form account-form res:mt-4"
                layout="vertical"
                initialValues={{
                    gadsCustomerId: franchise.gadsCustomerId,
                    fadsAccountId: franchise.fadsAccountId,
                    gAnalyticsId: franchise.gAnalyticsId,
                    stiristaId: franchise.stiristaId,
                }}
            >
                <div className="grid grid-cols-1 gap-x-6 gap-y-6">
                    <div className="flex items-end gap-x-12">
                        <Form.Item
                            name="gadsCustomerId"
                            className="mb-0"
                        >
                            <Input placeholder="Google Ads account ID" />
                        </Form.Item>

                        {   onboardingFranchise && 
                            onboardingFranchise.gadsStat && 
                            onboardingFranchise.gadsStat === "verified" &&
                            franchise.gadsCustomerId 
                        ? (
                            <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                                <span>Verified</span>
                                <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#009051' }}></span>
                            </div>
                        ): franchise.gadsCustomerId && (
                            <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                                <span>Pending</span>
                                <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#FF5566' }}></span>
                            </div>
                        )}

                        
                    </div>

                    <div className="flex items-end gap-x-12">
                        <Form.Item
                            name="fadsAccountId"
                            className="mb-0"
                        >
                            <Input placeholder="Meta Ads account ID" />
                        </Form.Item>

                        {   onboardingFranchise && 
                            onboardingFranchise.madsStat && 
                            onboardingFranchise.madsStat === "verified" &&
                            franchise.fadsAccountId
                        ? (
                            <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                                <span>Verified</span>
                                <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#009051' }}></span>
                            </div>
                        ): franchise.fadsAccountId && (
                            <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                                <span>Pending</span>
                                <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#FF5566' }}></span>
                            </div>
                        )}
                    </div>

                    <div className="flex items-end gap-x-12">
                        <Form.Item
                            name="gAnalyticsId"
                            className="mb-0"
                        >
                            <Input placeholder="Analytics account ID" />
                        </Form.Item>

                        {   onboardingFranchise && 
                            onboardingFranchise.ganaStat && 
                            onboardingFranchise.ganaStat === "verified" &&
                            franchise.gAnalyticsId
                        ? (
                            <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                                <span>Verified</span>
                                <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#009051' }}></span>
                            </div>
                        ): franchise.gAnalyticsId && (
                            <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                                <span>Pending</span>
                                <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#FF5566' }}></span>
                            </div>
                        )}
                    </div>

                    <div className="flex items-end gap-x-12">
                        <Form.Item
                            name="stiristaId"
                            className="mb-0"
                        >
                            <Input placeholder="DSP advertiser ID" />
                        </Form.Item>

                        {   onboardingFranchise && 
                            onboardingFranchise.dspStat && 
                            onboardingFranchise.dspStat === "verified" &&
                            franchise.stiristaId
                        ? (
                            <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                                <span>Verified</span>
                                <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#009051' }}></span>
                            </div>
                        ): franchise.stiristaId && (
                            <div className="flex items-center justify-between px-3 py-3 bg-white shadow-lg rounded-xl">
                                <span>Pending</span>
                                <span className="w-4 h-4 ml-10 rounded-full" style={{ backgroundColor: '#FF5566' }}></span>
                            </div>
                        )}
                    </div>

                    <div className="flex items-end justify-start w-full">
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                className="primary-button pointer-hover"
                                style={{ backgroundColor: whiteLabel.primaryColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >

                                <div className="primary-button-text">SAVE</div>
                            </Button>
                        </Form.Item>
                    </div>
                </div>

                <AlertMessage successMessage="IDs saved" openScsMsg={openSuccessMsg} onScsClose={() => setOpenSuccessMsg(false)} openErrMsg={openErrorMsg} onErrClose={() => setOpenErrorMsg(false)}/>

            </Form>
        )}
    </>)
}

export default OnboardingSystem;