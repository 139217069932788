import React, { useEffect, useRef, useState } from "react";
import "./ScheduleCampaign.scss";
import { ReactComponent as DirectMailIcon } from "../../../assets/icons/DirectMailIcon.svg";
import { ReactComponent as DigitalIcon } from "../../../assets/icons/DigitalIcon.svg";
import { ReactComponent as OttIcon } from "../../../assets/icons/oot-icon.svg";
import { ReactComponent as NativeIcon } from "../../../assets/icons/native-icon.svg";
import { ReactComponent as CtvOttIcon } from "../../../assets/icons/ctv-ott-icon.svg";
import { ReactComponent as SocialIcon } from "../../../assets/icons/FacebookIcon.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/EmailIcon.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/icons/google-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "@mui/material";
import AlwaysOnTag from "./AlwaysOnTag";
import {
  selectAlwaysOn,
  updateNewCampaignChannel,
  selectIsBudgetModeFixed,
  selectedMonth,
} from "../../../store/campaignForm/CampaignForm";
import { NewCampaignChannel } from "../../../models/CampaignChannel";
import { MILLISECONDS_IN_SECOND, MINUTES_IN_HOUR, SECONDS_IN_MINUTE, addDaysToDate } from "../../../utility/time";
import DatePicker from "../../datepicker/DatePicker";
import { isBefore, isAfter } from "date-fns";
import ChannelDetailsPopover from "./ChannelDetailsPopover";
import { Button } from "antd";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { getMonthLastDate, getMonthIndexByCode } from "../../../models/MonthlyBudget";

interface ChannelCardProps {
  campaignChannel?: NewCampaignChannel;
  onSelectedDateChange?: Function;
  minimumStart: Date;
}

const ChannelCard = ({
  campaignChannel,
  onSelectedDateChange,
  minimumStart,
}: ChannelCardProps) => {
  const isFixedBudgetMode = useSelector(selectIsBudgetModeFixed);
  const alwaysOn = useSelector(selectAlwaysOn);
  const channelType = campaignChannel?.channelType;
  const [endDate, setEndDate] = useState<Date>(campaignChannel?.endDate || addDaysToDate(minimumStart, 7));
  const [minimumEnd, setMinimumEnd] = useState<Date | null>(campaignChannel?.endDate || null);
  const month = useSelector(selectedMonth);

  useEffect(() => {
    if (isFixedBudgetMode) {
      // minimum end date in the fixed budget mode is actually acting as maximum end date.
      if (minimumEnd && endDate > minimumEnd) {
        updateEndDate(minimumEnd);
      }
      return;
    }

    if (!alwaysOn && (!campaignChannel?.endDate || (minimumEnd && endDate < minimumEnd))) {
      updateEndDate(minimumEnd);
    }
  }, [minimumEnd, endDate, alwaysOn]);

  useEffect(() => {
    if (channelType !== undefined) {
      const durationInMilliseconds =
        channelType.durationHours *
        MINUTES_IN_HOUR *
        SECONDS_IN_MINUTE *
        MILLISECONDS_IN_SECOND;
      const minDate = new Date(startDate.getTime());

      minDate.setTime(minDate.getTime() + durationInMilliseconds);

      if (!minimumEnd) {
        setMinimumEnd(new Date(minDate.getTime()));
      }
    }
  }, [campaignChannel]);

  useEffect(() => {
    if (isFixedBudgetMode) {
      const monthLastDate = getMonthLastDate(getMonthIndexByCode(month));
      if (minimumEnd && minimumEnd !== monthLastDate) {
        setMinimumEnd(monthLastDate);
        setEndDate(monthLastDate);
        updateEndDate(monthLastDate);
      }
    }
  }, [month]);

  const updateEndDate = (newEnd: Date | null): void => {
    if (newEnd !== null && campaignChannel) {
      const newDate = new Date(newEnd.toDateString());
      newDate.setHours(0, 0, 0, 0);
      setEndDate(newDate);
      dispatch(
        updateNewCampaignChannel({
          ...campaignChannel,
          endDate: newDate,
          readyToDisplay: startDate !== null,
        })
      );
      
    }
  };

  //Both
  const dispatch = useDispatch();
  const title = channelType?.title || "";
  const [startDate, setStartDate] = useState<Date>(minimumStart);
  const detailsRef = useRef<HTMLButtonElement>(null);
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const updateStartDate = (newStart: Date | null): void => {
    if (newStart !== null) {
      const newDate = new Date(newStart.toDateString());
      newDate.setHours(0, 0, 0, 0);
      setStartDate(newDate);
      if (campaignChannel) {
        dispatch(
          updateNewCampaignChannel({
            ...campaignChannel,
            readyToDisplay: endDate !== null || alwaysOn,
            startDate: newDate,
          })
        );
      }
      if( onSelectedDateChange != undefined){
        onSelectedDateChange( newDate );
      }
    }
  };

  useEffect(() => {
    if (startDate < minimumStart) setStartDate(minimumStart);
    updateStartDate(minimumStart);
  }, [minimumStart]);

  useEffect(() => {
    if (!minimumEnd) {
      setMinimumEnd(addDaysToDate(startDate, 7));
    }
  }, [startDate, minimumEnd]);

  const [tooltipIsOpenStart, setTooltipIsOpenStart] = useState(false);
  const [tooltipIsOpenEnd, setTooltipIsOpenEnd] = useState(false);

  const whiteLabel = useSelector(selectWhiteLabel);

  

  return (
    <div className={`channel-card `}>
      <div className="title res:!basis-full res:!justify-start">
        <div className="package-link">
          <span>{title}</span>
        </div>
      </div>

      <div className="channel-date res:!basis-7/12">
        <div className="input-container res:w-full">
          <div className="channel-datepicker-label">
            Start Date
          </div>
          <div className="channel-data-element">
            <DatePicker
              value={startDate ?? undefined}
              onChange={(date) => updateStartDate(date)}
              style={{ borderRadius: "4px" }}
              disabledDate={(d) => {
                if (isFixedBudgetMode) {
                  return !d
                      || isBefore(d, minimumStart ?? addDaysToDate(minimumStart, 1))
                          || isAfter(d, minimumEnd ?? addDaysToDate(minimumStart, 1));
                }
                return !d || isBefore(d, minimumStart);
              }}
            />
          </div>
        </div>
      </div>

      {(alwaysOn && campaignChannel) ?
        <div className="channel-end res:!basis-5/12">
          <AlwaysOnTag />
        </div>
        :
        <div className="channel-date  res:!basis-5/12">
          <div className="input-container">
            <div className="channel-datepicker-label">
              End Date
            </div>
            <div className="channel-data-element">
              <DatePicker
                value={endDate ?? undefined}
                onChange={(date) => updateEndDate(date)}
                style={{ borderRadius: "4px" }}
                disabledDate={(d) => {
                  if (isFixedBudgetMode) {
                    return !d
                        || isAfter(d, minimumEnd ?? addDaysToDate(minimumStart, 1))
                        || isBefore(d, minimumStart ?? addDaysToDate(minimumStart, 1));
                  }
                  return !d || isBefore(d, minimumEnd ?? addDaysToDate(minimumStart, 1));
                }
              }
              />
            </div>
          </div>
        </div>
      }
    </div >
  );
};

export default React.memo(ChannelCard);
