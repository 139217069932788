import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";
import { Account } from "../../models/Account";
import { AdCampaign } from "../../models/AdCampaign";
import { NewCampaignChannel } from "../../models/CampaignChannel";
import { NewCampaignCustomPackage } from "../../models/CampaignPackage";
import { TargetZone } from "../../models/TargetZone";
import services from "../../services";
import { RootState } from "../index";
import { MonthlyPlatformOrders } from "services/adCampaignService";

export const fetchAdCampaigns = createAsyncThunk(
  "adCampaigns/fetch",
  async ({ id }: Account) => {
    return services.adCampaignService.getAdCampaigns();
  }
); 

export const fetchPlatformOrderList = createAsyncThunk(
  "adCampaigns/monthly-platform-orders",
  async () => {
    console.log('fetching monthly platform orders...');
    return services.adCampaignService.getMonthlyPlatformOrders();
  }
);

export const createAdCampaign = createAsyncThunk(
  "adCampaigns/create",
  async ({
    account,
    newCampaignChannels,
    newPackages,
    audienceSize,
    areaInMiles,
    costInDollars,
    locationIds,
    cardId,
    zones,
    adjustedSpendPercent,
    alwaysOn,
    zips,
    month,
    segments,
    isNewBudget,
    newBudget,
    billingMode
  }: {
    account: Account;
    newCampaignChannels: NewCampaignChannel[];
    newPackages: NewCampaignCustomPackage[];
    audienceSize: number;
    areaInMiles: number;
    costInDollars: number;
    locationIds: string[];
    cardId: string;
    zones: TargetZone[];
    adjustedSpendPercent: number;
    alwaysOn: boolean;
    zips: string[];
    month: string | null;
    segments: string[];
    isNewBudget: boolean;
    newBudget: number;
    billingMode: string;
  }) => {
    return services.adCampaignService.createAdCampaign(
      account.id,
      newCampaignChannels,
      newPackages,
      audienceSize,
      areaInMiles,
      costInDollars,
      locationIds,
      cardId,
      zones,
      adjustedSpendPercent,
      alwaysOn,
      zips,
      month,
      segments,
      isNewBudget,
      newBudget,
      billingMode
    );
  }
);

export const adCampaignSlice = createSlice({
  name: "adcampaign",
  initialState: {
    adCampaigns: [] as AdCampaign[],
    newAdCampaign: null as AdCampaign | null,
    createCampaignLoading: false as boolean,
    platformOrderList: [] as MonthlyPlatformOrders[],
    loading: true,
  },
  reducers: {
    setAdCampaigns: (
      state,
      { payload: campaigns }: PayloadAction<AdCampaign[]>
    ) => {
      state.adCampaigns = campaigns;
    },
    setNewAdCampaign: (
      state,
      { payload: campaign }: PayloadAction<AdCampaign | null>
    ) => {
      state.newAdCampaign = campaign;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdCampaigns.fulfilled, (state, action) => {
      state.adCampaigns = action.payload;
    });
    builder.addCase(createAdCampaign.fulfilled, (state, action) => {
      state.newAdCampaign = action.payload;
      state.adCampaigns.push(action.payload);
      state.createCampaignLoading = false;
    });
    builder.addCase(createAdCampaign.pending, (state, action) => {
      state.createCampaignLoading = true;
    });
    builder.addCase(createAdCampaign.rejected, (state, action) => {
      state.createCampaignLoading = false;
    });
    builder.addCase(fetchPlatformOrderList.fulfilled, (state, action) => {
      state.platformOrderList = action.payload;
      console.log(action.payload);
      state.loading = false;
    });
    builder.addCase(fetchPlatformOrderList.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const selectAdCampaigns = (state: RootState): AdCampaign[] =>
  state.adCampaigns.adCampaigns;

export const selectNewAdCampaign = (state: RootState): AdCampaign | null =>
  state.adCampaigns.newAdCampaign;

export const selectCreateCampaignLoading = (state: RootState): boolean =>
  state.adCampaigns.createCampaignLoading;

export const selectMonthlyPlatformOrderList = (state: RootState): MonthlyPlatformOrders[] =>
  state.adCampaigns.platformOrderList;

export const selectLoading = (state: RootState) : boolean =>
  state.adCampaigns.loading;

export const { setAdCampaigns, setNewAdCampaign } = adCampaignSlice.actions;

export default adCampaignSlice.reducer;
