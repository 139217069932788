import React, { useState, useEffect } from "react";
import { Form, Input, Button, Space, Radio, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserAccount, updateUser } from "../../../store/Account/AccountStore";
import { CountryEnum } from "../../../models/Location";
import { UsStates } from "../../account/BillingAddress/UsStates";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import AlertMessage from "../../alertMessage/AlertMessage";

export const OnboardingProfile = () => {
    
    const dispatch = useDispatch();
    const account = useSelector(selectUserAccount);
    const { Option } = Select;
    const navigate = useNavigate();
    const [openSuccessMsg, setOpenSuccessMsg] = useState<boolean>(false);
    const [openErrorMsg, setOpenErrorMsg] = useState<boolean>(false);

    const whiteLabel = useSelector( selectWhiteLabel );

    //From the billing address module
    const [usSelected, setUsSelected] = useState(false);

    useEffect(() => {
        if(account?.country === CountryEnum.USA)
        setUsSelected(true);
        else
        setUsSelected(false);
    }, [account]);
    
    const handleCountryChange = (value: string) => {
        if(value === CountryEnum.USA)
        setUsSelected(true);
        else
        setUsSelected(false);
    }
    
    const renderOptions = (option: string) => {
        return <Option key={option} value={option}>{option}</Option>
    }
    
    const onFinish = (values: any) => {
        dispatch(updateUser({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            communicationPreference: values.communicationPreference,
            address1: values.address1,
            address2: values.address2,
            state: values.state,
            postalCode: values.postalCode,
            city: values.city,
            country: values.country,
        }));
        setOpenSuccessMsg(true);
    };
    
    const onFinishFailed = (errorInfo: any) => {
        setOpenErrorMsg(true);
        console.log("Failed:", errorInfo);
    };

    return ( <>
        {account && (
        <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="w-9/12 container-form account-form res:mt-4"
            layout="vertical"
            initialValues={{
                firstName: account.firstName,
                lastName: account.lastName,
                email: account.email,
                phoneNumber: account.phoneNumber,
                communicationPreference: account.communicationPreference,
                address1: account.address1,
                address2: account.address2,
                state: account.state,
                postalCode: account.postalCode,
                city: account.city,
                country: account.country ? account.country : CountryEnum.USA,
            }}
        >
            
            <div className="grid grid-cols-2 gap-x-6 gap-y-6">
                <Form.Item
                name="firstName"
                >
                <Input placeholder="Name" />
                </Form.Item>

                <Form.Item
                name="lastName"
                >
                <Input placeholder="Last Name" />
                </Form.Item>
                

                <Form.Item
                    name="email"
                    rules={[
                    {
                        type: "email",
                        message: "not valid email",
                    },
                    ]}
                >
                    <Input placeholder="Email" disabled />
                </Form.Item>

                <Form.Item
                    name="phoneNumber"
                >
                    <Input  placeholder="Phone Number" />
                </Form.Item>

                <Form.Item
                    name="address1"
                >
                    <Input placeholder="Address 1" />
                </Form.Item>

                <Form.Item
                    name="address2"
                    required={false}
                >
                    <Input placeholder="Address 2" />
                </Form.Item>

                <Form.Item  name="city">
                    <Input placeholder="City" />
                </Form.Item>

                <Form.Item
                name="state"
                >
                    {usSelected ?
                        <Select >
                            {UsStates.map(renderOptions)}
                        </Select>
                        :
                        <Input  placeholder="State"/>
                    }
                </Form.Item>

                <Form.Item
                    name="postalCode"
                >
                    <Input placeholder="Postal Code" />
                </Form.Item>
                
                <Form.Item
                    name="country"
                >
                    <Select placeholder="Country" onChange={handleCountryChange}>
                        {Object.keys(CountryEnum).map(renderOptions)}
                    </Select>
                </Form.Item>
                

                <Form.Item
                    name="communicationPreference"
                    label={<p style={{ fontSize: "16px" }}>Communication Preference</p>}
                    style={{ fontSize: 16 }}
                >
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value="email">Email</Radio>
                            <Radio value="SMS">SMS</Radio>
                            <Radio value="both">Both</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </div>
            

            <div className="flex items-end justify-between w-full">
                <div
                    className="change-password"
                    onClick={() => navigate("/change-password")}
                >
                    Change Password
                </div>
            </div>

            <div className="flex items-end justify-between w-full">
                <Form.Item>
                    <Button
                        htmlType="submit"
                        className="primary-button pointer-hover"
                        style={{ backgroundColor: whiteLabel.primaryColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >

                        <div className="primary-button-text">SAVE</div>
                    </Button>
                </Form.Item>
            </div>

            <AlertMessage successMessage="Account Information was saved successfully!" openScsMsg={openSuccessMsg} onScsClose={() => setOpenSuccessMsg(false)} openErrMsg={openErrorMsg} onErrClose={() => setOpenErrorMsg(false)}/>
        </Form>
      )}
        
    </>)
}

export default OnboardingProfile;