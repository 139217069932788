import { OnboardingFranchise } from "../models/OnboardingFranchise";
import RestService from "./restService";



export default class OnboardingFranchiseService {
  constructor(protected readonly api: RestService) {}

  async getOnboardingFranchise(): Promise<OnboardingFranchise> {
    return await this.api.get<OnboardingFranchise>(`/onboarding-franchise`);
  }

  async updateOnboardingFranchise(updateOnboardingFranchise: OnboardingFranchise): Promise<OnboardingFranchise> {
    return await this.api.put<OnboardingFranchise>("/onboarding-franchise/update", updateOnboardingFranchise);
  }


}
