import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";

export const NavigationSlice = createSlice({
  name: "navigation",
  initialState: {
    selectedMainMenu: null as String | null,
    selectedSubMenu: null as String | null,
    showActivitySideBar: false,
    stateSideBarChannel: "open" as any | null,
  },
  reducers: {
    setSelectedMainMenu: (
      state,
      { payload: mainMenu }: PayloadAction<String>
    ) => {
      state.selectedMainMenu = mainMenu;
    },
    setSelectedSubMenu: (
      state,
      { payload: subMenu }: PayloadAction<String>
    ) => {
      state.selectedSubMenu = subMenu;
    },
    setShowActivitySideBar: (
      state,
      { payload: show }: PayloadAction<boolean>
    ) => {
      state.showActivitySideBar = show;
    },
    setStateSideBarChannel: (
      state,
      { payload: sideBarChannel }: PayloadAction<String>
    ) => {
      state.stateSideBarChannel = sideBarChannel;
    },
  },
});

export const selectSelectedMainMenu = (state:RootState): String | null =>
  state.navigation.selectedMainMenu;

export const selectSelectedSubMenu = (state:RootState): String | null =>
  state.navigation.selectedSubMenu;

export const selectShowActivitySideBar = (state:RootState): boolean =>
  state.navigation.showActivitySideBar;

export const stateSideBarChannel = (state:RootState): any | null =>
  state.navigation.stateSideBarChannel;

export const { setSelectedMainMenu, setSelectedSubMenu, setStateSideBarChannel, setShowActivitySideBar } = NavigationSlice.actions;
  
export default NavigationSlice.reducer;
