export enum CountryEnum {
  USA = "USA",
  CAN = "CAN",
  MEX = "MEX"
}

export enum BudgetEnum {
  none = "Standard",
  fixed = "Fixed"
}

export interface Place {
  placeId: string;
  lat:number;
  lng: number;
}

export interface LatLonPairForZip{
  zip: string;
  lat: number;
  lng: number;
}

export interface Location {
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  proximity: string | null;
  city: string;
  state: string;
  postalCode: string;
  country: CountryEnum | null;
  phoneNumber: string;
  latitude: number | null;
  longitude: number | null;
  externalId: string | null;
  ownerEmail: string | null;
  userId: string | null;
  gadsCampaignId: string;
  fadsAdSetId: string;
  isRegion: number | null | boolean;
  scope:string | null;
  scopeLabel: string | null;
  places: Place[];
  marketName: string;
  primaryZips: string | null;
  secondaryZips: string | null;
  primaryCoords: LatLonPairForZip[],
  secondaryCoords: LatLonPairForZip[],
  monthlyCpl: string;
  secondUrl: string;
  searchDaily: number; 
  socialDaily: number;
  budgetMode: BudgetEnum | null;
  franchiseId: string | null;
  mapEmbed: string | null; 
  nickname: string | null;
  vigI: string | null;
  vigS: string | null;
  active: boolean;
}
